import { Children, Dispatch, HTMLProps, ReactPropTypes, SetStateAction } from "react";
import styles from "./styles.module.css"
import { IoCloseOutline } from "react-icons/io5";

type PropTypes = HTMLProps<HTMLDivElement> & {
    setShowModal: Dispatch<SetStateAction<boolean>>
}

export function Modal({setShowModal, ...props}: PropTypes) {


    return(

        <div className={styles.modalContainer}>

            <div className={styles.modal}>
                <div className={styles.modalHeader}>
                    <h3>Sua Avaliação</h3>
                    <IoCloseOutline onClick={() => setShowModal(false)} className={styles.closeIcon}/>
                </div>
                <div className={styles.modalBody} {...props} />

                {/* <div className={styles.modalFooter}>

                    <button>Cancelar</button>
                    <button>Confirmar</button>
                </div> */}
            </div>  

        </div>
    )
}