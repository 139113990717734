export type PagamentoType ={
    "Z1_FILIAL": string
    "Z1_NUMPV":string
    "Z1_TABELA": string
    "Z1_CEP": string
    "Z1_ASSIST": string
    "Z1_DTAGEND": string
    "Z1_TURNO": string
    "Z1_ENDER": string
    "Z1_NUMERO": string
    "Z1_COMPLE": string
    "Z1_BAIRRO": string
    "Z1_EST": string
    "Z1_MUN": string
    "Z1_FPGTO": "C"|"P"
    "Z1_TERMO": string
    "Z1_PERASSI": string
    "Z1_STATUS": string
    "Z1_AVALIAC": string
    "Z1_OBSERV": string
    "Z1_DTVISIT": string
    "Z1_HRVISIT": string
    "Z1_PARCEIR": string
    "Z1_LOJA": string
    "Z1_TECNICO": string
    "Z1_LOJTEC": string
    "Z1_DTCHKIN": string
    "Z1_HRCHKIN":string
    "Z1_DTCHKOU": string
    "Z1_HRCHKOU": string
    "Z1_LATITUD": string
    "Z1_LONGITU":string
    "Z1_MOTCANC": string
    "Z1_ORIGEM": "C"|"T",
    "Z1_TXCC": string
    "Z1_TXSERV": string
    "Z1_CHVSE1": string
    "Z1_CHVSE2": string
    "Z1_TXIMPO": string
    "Z1_OKPGTO": string
    "Z1_NUMPVNF": string
    "C5_EMISSAO":string
    "C5_NOTA": string
    "A1_NOME": string
    "B1_DESC": string
    "STATUS": "1"|"2"|"3"
    "VRTOTAL": number
    "VENCIMENTO": string
    "PAGAMENTO": string
}

export const STATUS_PAGAMENTO: any = {
    "1": "Disponível",
    "2": "A Pagar",
    "3": "Pago",
}