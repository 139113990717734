import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { Link, useNavigate } from 'react-router-dom';
import { ReactNode, useContext, useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";
import { FcViewDetails } from "react-icons/fc";
import { FaStar } from "react-icons/fa6";
import { MdOutlineStarOutline, MdOutlineStarPurple500 } from "react-icons/md";
import { FaClipboard, FaClipboardCheck } from "react-icons/fa6";
import { Menu } from "../menu";
import { Modal } from "../../component/modal";

export function Servicos() {


    const { state: { usuario } } = useContext(ContextRegister)

    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)
    const [servicos, setServicos] = useState([])
    const turno: any = {
        "M": "Manhã",
        "T": "Tarde",
        "N": "Noite",
    }

    const status: any = {
        1: "Aguardando",
        2: "Cancelado",
        3: "Finalizado"
    }

    const formaterCurrency = new Intl.NumberFormat('pt-br', {
        currency: 'BRL',
        style: 'currency'
    })

   

    function dateFormater(dataStr: string) {

        if(!dataStr && dataStr === "") {
            return ""
        }

        //dataStr AAAAMMDD
        return new Date(`${dataStr.slice(0, 4)}-${dataStr.slice(4, 6)}-${dataStr.slice(6, 8)}`).toLocaleString('pr-BR', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })

    }

    const { state, dispatch } = useContext(ContextRegister)

    async function buscarServicos() {

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados dos serviços.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(`${url}COLETAATENDIMENTO`, {
            method: "POST",
            body: JSON.stringify(
                { 
                    TIPO: "P",
                    PARCEIRO: state.usuario.A2_COD,
                    LOJA: state.usuario.A2_LOJA,
                    STATU: "1"
                }
            )
        })
            .then((retorno: any) => {
                if (retorno.sucesso !== 'T') {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao buscar os servicos!" + retorno.mensagem,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    return;
                }

                if (Swal.isLoading()) {
                    Swal.close()
                }


                setServicos(retorno.dados)


            })
            .catch(error => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao buscar dados!" + error,
                    showConfirmButton: false,
                    timer: 1500
                });
            })

    }

    


    async function pegarServico(servico: any) {

        let aux: any = [ ...servicos]

        aux.find( (a: any) => a.Z1_NUMPV === servico.Z1_NUMPV).Z1_STATUS = "SELECIONADO"

        setServicos(aux)

        
        
        Swal.fire({
            title: "Selecionando Serviço",
            text: "Aguarde enquanto estamos selecionando seu serviço!",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
            
        })

        AuthFetchJson(`${url}INSEREVISITA`, {
            method: "POST",
            body: JSON.stringify({
                DADOS: {

                    "Z1_PARCEIR": usuario.A2_COD,
                    "Z1_LOJA": usuario.A2_LOJA,
                    "Z1_FILIAL": servico.Z1_FILIAL,
                    "Z1_NUMPV": servico.Z1_NUMPV,
                    "Z1_DTVISIT": "",
                    "Z1_HRVISIT": "",
                    "Z1_TECNICO": "",
                    "Z1_LOJTEC": ""
                }
            })
        }).then(retorno => {


            if(retorno.sucesso != "T") {
                Swal.fire({
                    title: "Pegar Serviço",
                    text: "Erro ao selecionar serviço!",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false
                })
            } else {

                Swal.fire({
                    title: "Avaliação",
                    text: "Avaliaçao realizada com sucesso!",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false
                })


                navigate('/meus-servicos')


            }
        }).catch(error => {
            console.log(error)
            Swal.fire({
                title: "Avaliação",
                text: "Erro ao realizar avaliaçao!",
                icon: "error",
                timer: 1500,
                showConfirmButton: false
            })
        }). finally(() => {

            if(Swal.isLoading()) {
                Swal.close()
            }
        })
    }


    useEffect(() => {


        buscarServicos()
        

    }, [])

    return (

        <div className={styles.container}>

            <Menu />

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/home')}>
                    {'< Voltar'}
                </p>
            </div>

            <div className={styles.content} >




                <table>

                    <thead>
                        <tr>
                            <th>OS</th>
                            <th>Estado</th>
                            <th>Cidade</th>
                            <th>Bairro</th>
                            <th>Data Emissão</th>
                            <th>Data Agendada</th>
                            <th>Data Retorno</th>
                            <th>Horário</th>
                            <th>Pragas</th>
                            <th>Valor</th>
                            {/* <th>Avaliação</th> */}
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            servicos.map((servico: any) => (
                                <tr key={servico.Z1_NUMPV}>
                                    <td>{servico.Z1_NUMPV}</td>
                                    <td>{servico.Z1_EST}</td>
                                    <td>{servico.Z1_MUN}</td>
                                    <td>{servico.Z1_BAIRRO}</td>
                                    <td>{dateFormater(servico.C5_EMISSAO)}</td>
                                    <td>{dateFormater(servico.Z1_DTAGEND)}</td>
                                    <td>{dateFormater(servico.Z1_DTRETOR)}</td>
                                    <td>{turno[servico.Z1_TURNO]}</td>
                                    <td>{servico.PRAGAS.map((p: any) => p.DESCRICAO).join(', ')}</td>
                                    <td>{formaterCurrency.format(servico.VRTOTAL)}</td>
                                    {/* <td>{status[servico.Z1_STATUS]}</td> */}
                                    <td className={styles.action}>
                                        {servico.Z1_STATUS === "SELECIONADO" ?
                                        <FaClipboardCheck title="Pegar Serviço" />
                                        :
                                        <FaClipboard title="Pegar Serviço" onClick={() =>pegarServico(servico)} />
                                        }
                                    </td> 
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>

    )

}