import { useNavigate } from "react-router-dom";
import { Menu } from "../menu";
import styles from "./styles.module.css"
import { useContext, useEffect, useState } from "react";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";


type InsumoType = {
    B1_COD?: string
    B1_DESC: string
    checked?: boolean
    Z6_CODINS?: string
}

export function Insumos() {

    const { state } = useContext(ContextRegister)
    const navigate = useNavigate()
    const [meusInsumos, setMeusInsumos] = useState<InsumoType[]>([])
    const [insumos, setInsumos] = useState<InsumoType[]>([]);

    const [termo, setTermo] = useState("")

    function buscarMeusInsumos() {

        AuthFetchJson(`${url}COLETAINSPARCEIRO`, {
            method: "POST",
            body: JSON.stringify({
                CODPAR: state.usuario.A2_COD,
                LOJA: state.usuario.A2_LOJA
            })

        })
            .then(response => {

                if (response.sucesso !== "T") {
                    Swal.fire("Erro ao buscar insumos associados.")
                    return;
                }
                setMeusInsumos(response.dados)

            })
            .catch(error => {
                Swal.fire("Erro ao buscar insumos associados.")
                console.log(error)
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })


    }

    function handleCheckIns(index: number, obj:  InsumoType){
        let aux = [ ...insumos ]

        index = aux.findIndex( a => a.B1_COD === obj.B1_COD)

        aux.splice(index, 1, { ...obj, checked: !obj.checked })
        setInsumos(aux)

    }

    function handleSave() {

        Swal.fire({
            title: "Salvando",
            html: "Aguarde, gravando dados de insumos",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        let insumosSave = insumos.filter(insumo => insumo.checked).map(insumo =>({
            CODINS: insumo.B1_COD
        }))

        AuthFetchJson(`${url}INSUMOPARCEIRO`, {
            method: "POST",
            body: JSON.stringify( {
                CODPAR: state.usuario.A2_COD,
                LOJA: state.usuario.A2_LOJA,
                itens: insumosSave
            } )
        }).finally(() => {
            if (Swal.isLoading()) {
                Swal.close()
            }
        })
    }

    useEffect(() => {

        Swal.fire({
            title: "Carregando",
            html: "Carregando insumos cadastrados",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        AuthFetchJson(`${url}COLETAINSUMO`)
            .then(response => {

                if (response.sucesso !== "T") {
                    Swal.fire("Erro ao buscar insumos")
                    return;
                }

                setInsumos(response.dados.map((insumo: any) => ({

                    "B1_COD": insumo.B1_COD,
                    "B1_DESC": insumo.B1_DESC,
                    "checked": false

                })))
                buscarMeusInsumos()

            })
            .catch(error => {
                Swal.fire("Erro ao buscar insumos")
                console.log(error)
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })

    }, [])


    useEffect(() => {

        if(insumos.length === 0 || meusInsumos.length ===0) {
            return
        }


        let insumosInter = insumos.map(insumo => {

            if(meusInsumos?.some( i => i.Z6_CODINS === insumo.B1_COD)) {
                return {
                    ...insumo,
                    checked: true
                }
            }
            return insumo
        })


        setInsumos(insumosInter)

    }, [meusInsumos])

    return (
        <div className={styles.container}>

            <Menu />

            <div className={styles.content} >

                <div className={styles.insumoContainer}>
                    <h2>Insumos</h2>
                    <input placeholder="Pesquisar insumo" type="text" value={termo} onChange={event => setTermo(event.target.value)}/>


                    <div className={styles.insumosOverflow}>

                        {insumos?.filter(insumo => insumo.B1_DESC.toUpperCase().search(`${termo}`.toUpperCase()) !== -1).map((insumo, index) =>

                            <label key={insumo.B1_COD}>
                                <input type="checkbox" onChange={() => handleCheckIns(index, insumo)} value={insumo.B1_DESC} checked={insumo.checked} />
                                &nbsp;&nbsp;&nbsp;{insumo.B1_DESC}
                            </label>
                        )}
                    </div>

                    <button onClick={handleSave}>Salvar</button>

                </div>

            </div>
        </div>
    )
}