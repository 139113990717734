
import styles from "./styles.module.css"
import { GrClose } from "react-icons/gr";
import { HTMLAttributes, MouseEventHandler } from 'react';


type ModalProps = HTMLAttributes<HTMLDivElement> & {
    titulo: string
    fechar: Function
}


export function Modal({ titulo, fechar,  ...props }: ModalProps) {





    return (
        <div className={styles.modalContainer}>

            <div className={styles.modal}>


                <div className={styles.header}>
                    <p>{titulo}</p>
                    <GrClose className={styles.close}  onClick={() => fechar()}/>
                </div>

                <div className={styles.modalBody} {...props}></div>

                <div className={styles.modalFooter}>
                    <button type="button" onClick={() => fechar()}>Cancelar</button>
                    <button type="submit">Confirmar</button>
                </div>
            </div>


        </div>
    )
}