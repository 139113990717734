import styles from './styles.module.css'
import { Link } from 'react-router-dom';
import { Menu } from '../menu';
import { ReactEventHandler, useContext, useEffect, useState } from 'react';
import { PagamentoType, STATUS_PAGAMENTO } from '../../models/pagamento';
import { ContextRegister } from '../../context';
import { AuthFetchJson, url } from '../../api';
import Swal from 'sweetalert2';
import { Utils  } from '../../utils';

export function Home() {


    const [pagamentos, setPagamentos] = useState<PagamentoType[]>([])
    const { state } = useContext(ContextRegister);

    const [dataIni, setDataIni] = useState(() => (""))
    const [dataFim, setDataFim] = useState(() => (""))
    const [status, setStatus] = useState(() => "0")


    function filtrar() {

        if(pagamentos.length === 0) {
            return []
        } 

        if(status === "0") {
            return pagamentos
        }

        console.log(status)

        return pagamentos.filter(pg => pg.STATUS === status)
    }

    function handleData(data: string, dataOrder: number) {

        if(dataOrder === 1) {
            setDataIni(data)
            getPagamentos(Utils.formatDTtoAAAAMMDD(new Date(`${data} 00:00`)), Utils.formatDTtoAAAAMMDD(new Date(`${dataFim} 23:59`)))
            console.log(new Date(`${data} 00:00`))

        }

        if(dataOrder === 2) {
            setDataFim(data)
            getPagamentos(Utils.formatDTtoAAAAMMDD(new Date(`${dataIni} 00:00` )), Utils.formatDTtoAAAAMMDD(new Date(`${data} 23:59 `)))
            console.log(Utils.formatDTtoAAAAMMDD(new Date(`${data} 23:59`)))

        }

    }

    function getPagamentos(firstDate: string, lastDate: string) {
        Swal.fire({
            title: "Pagamentos",
            html: "Buscando dados de pagamentos.",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })
        const controller = new AbortController()
        const signal = controller.signal

        AuthFetchJson(`${url}FINANCEIROPARCEIRO`, {
            signal,
            method: "POST",
            body: JSON.stringify({
                "PARCEIRO": state.usuario.A2_COD,
                "LOJA": state.usuario.A2_LOJA,
                "DTVISITINI" : firstDate,
                "DTVISITFIM" : lastDate,
            })
        })
            .then(response => {

                if (response.sucesso !== "T") {

                    console.log(response)

                    Swal.fire({
                        title: "Pagamentos",
                        html: "Erro ao buscar pagamentos. " + response.mensagem
                    })
                    return;
                }

                setPagamentos(response.dados)

            })
            .catch(error => {

                if(error.ABORT_ERR === error.code) {
                    return
                }

                Swal.fire({
                    title: "Pagamentos",
                    html: "Erro ao buscar pagamentos."
                })
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })

            return controller
    }


    useEffect(() => {

        const now = new Date()

        const year = now.getFullYear()
        const month = now.getMonth()
        
        const firstDate = new Date(year, month, 1, 0, 0)
        const lastDate = new Date(year, month+1, 0, 0, 0)

        setDataIni(firstDate.toISOString().slice(0, 10))
        setDataFim(lastDate.toISOString().slice(0, 10))

        const controller = getPagamentos(Utils.formatDTtoAAAAMMDD(firstDate), Utils.formatDTtoAAAAMMDD(lastDate))

        return () => {
            controller.abort()
        }


    }, [])

    return (

        <div className={styles.container}>

            <Menu />

            <div className={styles.content}>


                    <h2>Pagamentos</h2>
                


                <div className={styles.filterContainer}>

                    <div className={styles.statusContainer} >
                        <label>Status</label>
                        <select onChange={event => setStatus(event.target.value)}>
                            <option value={0}>Todos</option>
                            {
                                Object.keys(STATUS_PAGAMENTO).map( v => 
                                    
                                    <option key={`${v}opt`} value={v}>{STATUS_PAGAMENTO[v]}</option>
                                )
                            }
                        </select>

                    </div>

                    <div className={styles.dataContainer}>
                        <label>Data Inicial e Final (Visita)</label>
                        <input value={dataIni} onChange={event => handleData(event.target.value, 1)}  type="date" placeholder='data inicial'/>
                        <input value={dataFim} onChange={event => handleData(event.target.value, 2)} type="date" placeholder='data final'/>
                    </div>

                </div>

                <div className={styles.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Descrição</th>
                            <th>Status</th>
                            <th>Data Agenda</th>
                            <th>Data Visita</th>
                            <th>Vencimento</th>
                            <th>Pagamento</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            filtrar()?.map((pg: PagamentoType) => (
                                <tr key={`${pg.Z1_NUMPV}PG`}>
                                    <td>{pg.A1_NOME}</td>
                                    <td>{pg.B1_DESC}</td>
                                    <td>{STATUS_PAGAMENTO[pg.STATUS]}</td>
                                    <td>{Utils.formatDtProtheus(pg.Z1_DTAGEND)}</td>
                                    <td>{Utils.formatDtProtheus(pg.Z1_DTVISIT)}</td>
                                    <td>{Utils.formatDtProtheus(pg.VENCIMENTO)}</td>
                                    <td>{Utils.formatDtProtheus(pg.PAGAMENTO)}</td>
                                    <td>{Utils.formaterCurrency.format(pg.VRTOTAL)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={7}></td>
                            <td>{Utils.formaterCurrency.format(pagamentos.reduce((acum, value) => acum + value.VRTOTAL, 0))}</td>
                        </tr>
                    </tfoot>
                </table>

                </div>

                
            </div>
        </div>

    )
}