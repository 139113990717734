import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { useNavigate, useParams } from 'react-router-dom';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { GrGrommet, GrRadialSelected } from "react-icons/gr";
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import Calendar from "react-calendar";
import { DadosBasicos } from "./dados-basicos";
import { Endereco } from "./endereco";
import { Contato } from "./contato";
import { CadastroType, TecnicoDefault } from "../../context/parceiro-cadastro";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";
import { Menu } from "../menu";
import { Utils } from "../../utils";

export const FormularioContext = createContext<{ formulario: CadastroType, setFormulario: Dispatch<SetStateAction<CadastroType>> } | null>(null)

export function EditarTecnico() {

    const { state: { usuario } } = useContext(ContextRegister)


    const parametros = useParams();

    const navigate = useNavigate();

    const [tab, setTab] = useState<'dadosbasicos' | 'endereco' | 'contato' | 'observacao'>('dadosbasicos')

    const [formulario, setFormulario] = useState(TecnicoDefault);



    function selectedTab() {

        switch (tab) {

            case 'dadosbasicos':
                return <DadosBasicos />
            case 'endereco':
                return <Endereco />
            case 'contato':
                return <Contato />
            default:
                return <DadosBasicos />

        }

    }

    function handleNext() {

        if( tab === 'dadosbasicos') {
            setTab("endereco")
            return
        }

        if( tab === 'endereco') {
            setTab("contato")
            return
        }

        if (tab === "contato") {


            let aux = { ...formulario }

            aux.A2_DTNASC = aux.A2_DTNASC?.replaceAll('-', '')
            aux.A2_CEP = aux.A2_CEP.replace(/\D/g, "")
            aux.A2_CGC = aux.A2_CGC.replace(/\D/g, "")
            if (aux.A2_NREDUZ === "") {
                aux.A2_NREDUZ = aux.A2_NOME.substring(0, 20)
            }


            if(aux.A2_ZOBSERV) {
                aux.A2_ZOBSERV = aux.A2_ZOBSERV.substring(0, 40)
            }

            if(aux.A2_ZSENHA === "" || aux.A2_ZSENHA_CONFIRM !== aux.A2_ZSENHA){

                delete aux.A2_ZSENHA
            }
            
            delete aux.A2_ZSENHA_CONFIRM

            
            Swal.fire({
                title: 'Cadastro!',
                html: 'Aguarde, salvando seus dados no sistema.',// add html attribute if you want or remove
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });


            AuthFetchJson(url + 'ALTERATECNICO', {
                body: JSON.stringify({ dados: { ...aux , PARCEIRO: usuario.A2_COD, LOJA: usuario.A2_LOJA} }),
                method: 'POST'
            })
                .then((retorno: any) => {


                    if (retorno.sucesso === 'T') {

                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Cadastro realizado com sucesso!",
                            showConfirmButton: false,
                            timer: 1500
                        });

                        navigate('/tecnicos')

                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Erro ao alterar tecnico, " + retorno.mensagem,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }


                })
                .catch((error) => {

                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao alterar tecnico!" + error,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    console.log(error)
                }).finally(() => {


                    if (Swal.isLoading()) {
                        Swal.close()
                    }

                })

        }

    }

    function getDadosTecnico() {

        Swal.fire({
            title: "Meus Dados",
            html: "Aguarde enquanto carregamos seus dados.",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })


        const controller = new AbortController()
        const signal = controller.signal

        AuthFetchJson(`${url}COLETAFORNECEDOR`, {
            signal,
            method: "POST",
            body: JSON.stringify({
                "A2_COD": atob(parametros.A2_COD|| ""),
                "A2_LOJA": usuario.A2_LOJA,
                "PARCEIRO": usuario.A2_COD,
                "LOJA": usuario.A2_LOJA,
                "TECFOR": "S"
            })
        })
            .then(response => {

                if (response.sucesso !== "T") {

                    Swal.fire({
                        title: "Meus Dados",
                        html: "Houve um erro ao buscar seus dados." + response.mensagem
                    })

                    return
                }

                response.dados[0].A2_CGC = Utils.maskaraCnpj(response.dados[0].A2_CGC) 
                setFormulario(response.dados[0])
            })
            .catch(err => {
                Swal.fire({
                    title: "Meus Dados",
                    html: "Houve um erro ao buscar seus dados."
                })
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })

        return () => {
            controller.abort()
        }

    }

    useEffect(() => {
        return  getDadosTecnico()
    }, [])

    return (
        <div className={styles.container}>

            <Menu/>

            <div className={styles.content} >

                <div className={styles.cardForm}>
                    <nav className={styles.menuSup}>
                        <p
                            onClick={() => setTab('dadosbasicos')}
                            className={tab === 'dadosbasicos' ? styles.menuSupSelected : ''}>Dados Básicos</p>
                        <p
                            onClick={() => setTab('endereco')}
                            className={tab === 'endereco' ? styles.menuSupSelected : ''}>Endereço</p>
                        <p
                            onClick={() => setTab('contato')}
                            className={tab === 'contato' ? styles.menuSupSelected : ''}>Contato</p>
                    </nav>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <FormularioContext.Provider value={{ formulario, setFormulario }}>
                        {
                            selectedTab()
                        }
                    </FormularioContext.Provider>

                    <hr />

                    <div className={tab === 'contato' ? styles.activeSubmitNext: styles.submitNext}>
                        <button onClick={handleNext}>
                            <span>Confirmar e finalizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>

            </div>


        </div>
    )
}