import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { useContext } from "react"
import { FormularioContext } from ".."

export function Contato() {

    const  context = useContext(FormularioContext)

    function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {
        
        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase()

        context?.setFormulario({ ...context.formulario, ...{ [nome]: value}})

    }

    return (
        <div className={styles.groupCardForm}>

                        {context?.formulario.A2_TIPO === "J" &&
                        <div className={styles.groupInput}>
                            <span>Contato <RxDotFilled className={styles.legend} /></span>
                            <input onChange={atualizarFormulario} value={context?.formulario.A2_CONTATO} name="A2_CONTATO" type="text" placeholder="Nome do Contato" style={{width: "100%"}} />
                            {/* <label>digite apenas números</label> */}
                        </div>
                        }

                        <div className={styles.formGroup}>
                            <div className={styles.groupInput}>
                                <span>Telefone <RxDotFilled className={styles.legend} /></span>
                                <input onChange={atualizarFormulario} value={context?.formulario.A2_TEL} name="A2_TEL" type="text"  placeholder="(00) 00000-0000)" />
                                <label>digite apenas números</label>
                            </div>
                        </div>
                        <div className={styles.groupInput}>
                            <span>E-mail <RxDotFilled className={styles.legend} /></span>
                            <input onChange={atualizarFormulario} value={context?.formulario.A2_EMAIL} name="A2_EMAIL" type="text" placeholder="teste@teste.com" style={{width: "100%"}} />
                            {/* <label>digite apenas números</label> */}
                        </div>
                    </div>
    )
}