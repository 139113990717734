import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { useNavigate, useParams } from 'react-router-dom';
import { RxDotFilled } from "react-icons/rx";
import { useContext, useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import Swal from 'sweetalert2';
import { AuthFetchJson, url } from "../../api";
import { ContextRegister } from "../../context";

import cep from "cep-promise"

export function ProtegerCasa() {
    const navigate = useNavigate();
    const parametros = useParams();
    
    const [detalhesImovel, setDetalhesImovel] = useState<any>([])
    const [pragas, setPragas] = useState<any>([])
    const [infos, setInfos] = useState<any>([])

    const [itens, setItens ] = useState<string[]>([])

    const [isOpen, setOpen] = useState(false)

    const [assistencia, setAssistencia] = useState(30)

    const [metragem, setMetragem] = useState('')

    const [tabela, setTabela] = useState('')

    const { state, dispatch } = useContext(ContextRegister);

    function selectItem(ZA_ITEM: string) {


        let index = itens.findIndex(i => i === ZA_ITEM)

        if(index === -1) {
            setItens([ ...itens, ZA_ITEM])
        } else {

            let aux = [...itens]

            aux.splice(index, 1)

            setItens(aux)
            console.log(aux)
        }


    }

    async function handleNext(event: React.FormEvent<any>) {

        event.preventDefault()


        let itensAux = [... itens]
        
        if(metragem === "") {

            Swal.fire({
                icon: "error",
                title: "Metragem é obrigatório!",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        let existePragaSelecionada = false;

        pragas.map( (praga: any) => {

            if(itens.includes(praga.ZA_ITEM)) {
                existePragaSelecionada = true

            }

        })


        if(!existePragaSelecionada) {
            Swal.fire({
                icon: "error",
                title: "Selecione pelo menos uma praga alvo!",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }



        Swal.fire({
            title: 'Aguarde!',
            html: 'Calculando valor do serviço.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });        
        

        detalhesImovel.map((d: any) => {

            if(d.ZA_FAIX1 <= parseInt(metragem) && d.ZA_FAIX2 >= parseInt(metragem)) {

                itensAux.push(d.ZA_ITEM)
            } 

        })


        let body = {

            TABELA: tabela,
            ASSDIAS: assistencia,
            ITENS: itensAux
        }


        let response: any  = await AuthFetchJson(url+'COLETAPRECO', {
            body: JSON.stringify(body),
            method: 'POST'
        })

        if(response.sucesso !== 'T') {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao gerar preço, tente novamente ou entre em contato com o suporte!",
            });
            return
        }


        


        if(Swal.isLoading()) {
            Swal.close()
        }

        response.dados[0]['ASSIS'] = assistencia
        dispatch({
            type: 'formacaoPreco',
            payload: response.dados[0]
        })

        navigate('/proteger-confirmacao/'+btoa(response.dados[0]['VALOR']))
    }


    async function buscaEndereco(cepStr: string, tabelaPraca: string) {


        try {
            let dados = await cep(cepStr);
            let aux = { ...state.agendamento }
    
            aux.CEP = cepStr
            aux.bairro = dados.neighborhood
            aux.estado = dados.state
            aux.municipio = dados.city
            aux.endereco = dados.street
            aux.tabela = tabelaPraca
    
            dispatch({
                type: 'agendamento',
                payload: aux
            })

        }catch(error) {

            let aux = { ...state.agendamento }
            aux.CEP = cepStr
            aux.tabela = tabelaPraca
            dispatch({
                type: 'agendamento',
                payload: aux
            })
            console.log(error)
        }

    }

    async function getCep() {

        let { value } = await Swal.fire({
            title: 'CEP',
            text: 'Digite o CEP de onde o serviço será executado!',
            icon: 'info',
            confirmButtonText: 'Confirmar',
            input: 'text',
            didClose: () => {
                if(!value)
                    getCep()
            }
        })

        coletaFiltros(value, parametros.tipo ?? '')
    }

    async function coletaFiltros(CEPIMOVEL: string, TPIMOVEL: string) {

        if (!CEPIMOVEL || CEPIMOVEL.length < 5){

            if(CEPIMOVEL !== undefined ) {
                getCep()
            }

            return
        }

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados da praça.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        try {


            let response: any = await AuthFetchJson(`${url}COLETAFILTROS`, {

                body: JSON.stringify({
                    CEPIMOVEL,
                    TPIMOVEL
                }),
                method: 'POST'
            })

            setDetalhesImovel(response?.dados[1]["IP02-METRAGEM"])
            setPragas(response?.dados[2]["IP03-PRAGAS"])
            setInfos(response?.dados[3]["IP04-CONDICIONANTES"])
            setTabela(response?.dados[0]["TABELA"])

            buscaEndereco(CEPIMOVEL, response?.dados[0]["TABELA"])

           

        } catch (error) {
            console.log(error)
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao buscar praça",
                showConfirmButton: false,
                timer: 1500
            });
        } finally {

            if(Swal.isLoading()) {
                Swal.close()
            }
        }
    }

    useEffect(() => {
        getCep()
    }, [])


    return (

        <div className={styles.container}>

            <div className={styles.menu}>

                <img src={logo} />

                <div className={styles.actions}>

                    <button>Nova solicitação</button>

                    <button>Serviços Executados</button>

                    <a href="">Minha conta</a>

                    <a href="">Sair</a>

                </div>

                <div className={styles.hamburguer}>

                    <Hamburger
                        toggled={isOpen}
                        toggle={setOpen} />

                </div>

            </div>

            {isOpen &&
                <nav className={styles.navMenu}>
                    <ul>
                        <li>
                            <a>Nova Solicitação</a>
                        </li>
                        <li>
                            <a>Serviços Executados</a>
                        </li>
                        <li>
                            <a>Minha Conta</a>
                        </li>
                        <li>
                            <a>Sair</a>
                        </li>
                    </ul>
                </nav>
            }

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>

            <div className={styles.content} >


                <p className={styles.urlButtonToFamily}>Uma casa</p>


                <div className={styles.cardForm}>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>
                    <form onSubmit={handleNext}>
                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Detalhes do imóvel</p>
                            <RxDotFilled className={styles.legend} />

                        </div>

                        <div className={styles.groupInput}>
                            <label>Tamanho do imóvel em m²</label>
                            <input min={1} placeholder="m²" type="number"  onChange={ (event: React.ChangeEvent<HTMLInputElement>) => setMetragem(event.target.value)}/>

                        </div>


                        {/* <div className={styles.radioGroupContainer}>
                                <div className={styles.radioGroup}>
                                    <input type="radio" name="metrosquadrados" /> 
                                    <span>&nbsp; 60m²</span>
                                </div>
                                <div className={styles.radioGroup}>
                                    <input type="radio" name="metrosquadrados" /> 
                                    <span>&nbsp; 60m² a 100m²</span>
                                </div>
                                <div className={styles.radioGroup}>
                                    <input type="radio" name="metrosquadrados" /> 
                                    <span>&nbsp; 100m² a 150m²</span>
                                </div>
                                <div className={styles.radioGroup}>
                                    <input type="radio" name="metrosquadrados" /> 
                                    <span>&nbsp; Mais que 150m²</span>
                                </div>

                            </div> */}
                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Proteção Contra</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>

                            {
                                pragas.map((p: any) => (

                                    <div key={p.ZA_ITEM} className={styles.radioGroup}>
                                        <input type="checkbox" value={p.ZA_ITEM} name="praga" onClick={() => selectItem(p.ZA_ITEM)} />
                                        <span>&nbsp; {p.ZA_DESCR}</span>
                                    </div>
                                ))
                            }


                        </div>
                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Informações importantes</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>

                            {
                                infos.map((i: any) => 
                                    <div className={styles.radioGroup}>
                                        <input type="checkbox" value={i.ZA_ITEM} name="info" onClick={() => selectItem(i.ZA_ITEM)}  />
                                        <span>&nbsp;  {i.ZA_DESCR}</span>
                                    </div>
                                )
                            }

                        </div>
                    </div>


                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Assistência técnica</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>
                            <div className={styles.radioGroup}>
                                <input type="radio" value='30' name="ASSDIAS" onClick={_ => setAssistencia(30)} checked={assistencia === 30} />
                                <span>&nbsp; 30 dias</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input type="radio" value='60' name="ASSDIAS" onClick={_ => setAssistencia(60)} checked={assistencia === 60} />
                                <span>&nbsp; 60 dias</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input type="radio" value='90' name="ASSDIAS"  onClick={_ => setAssistencia(90)} checked={assistencia === 90}/>
                                <span>&nbsp; 90 dias</span>
                            </div>

                        </div>
                    </div>

                    <div className={styles.submitNext}>
                        <button>
                            <span>Próximo</span>
                            <span>{' >'}</span>
                        </button>
                    </div>

                    </form>

                </div>
            </div>
        </div>

    )

}