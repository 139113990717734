import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { useContext } from "react"
import { FormularioContext } from ".."
import cep from 'cep-promise'
import Swal from "sweetalert2"

export function Endereco() {
    
    const  context = useContext(FormularioContext)


    function maskCep(v: string): string {
        v = v.replace(/\D/g, "")                    //Remove tudo o que não é dígito
        v= v.replace(/(\d{8})(\d)/, "$1")          //remove numeros que passarem da qunatidade de caracteres do cep
        v = v.replace(/(\d{2})(\d)/, "$1.$2")       //Coloca um ponto entre o segundo e terceiro dígitos
        v = v.replace(/(\d{3})(\d)/, "$1-$2")       //Coloca um hífen entre o sexto e sétimo dígitos
        return v
      }


    async function buscaCep(cepStr: string) {

        cepStr = cepStr.replace(/[^\w]/g, '')

        if (cepStr.length < 8) return;

        try {
            let adress = await cep(cepStr)


            console.log(adress)
            if(!adress?.city) return;

            context?.setFormulario({ ...context.formulario, 
                ...{ 
                    A2_MUN: adress.city,
                    A2_BAIRRO: adress.neighborhood, 
                    A2_EST: adress.state, 
                    A2_END: adress.street,
                    A2_CEP: maskCep(cepStr)
                }})


        }catch(error) {


            Swal.fire({
                position: "top-end",
                icon: "error",
                title: 'Erro ao buscar dados do CEP.' + error,
                showConfirmButton: false,
                timer: 1500
              });

        }


    }

    function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {



        
        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase()
        
        if(nome === 'A2_CEP') {

            value = maskCep(value)

            if (value.length > 8) {
                buscaCep(value)
            }       

        }
        context?.setFormulario({ ...context.formulario, ...{ [nome]: value}})

    }

    return(
        <div className={styles.groupCardForm}>


                        <div className={styles.groupInput}>
                            <span>CEP <RxDotFilled className={styles.legend} /></span>
                            <input value={context?.formulario.A2_CEP} onChange={atualizarFormulario} type="text" name="A2_CEP" placeholder="00.000-000" />
                            <label>digite apenas números</label>
                        </div>

                        <div className={styles.groupInput}>
                            <span>Endereço <RxDotFilled className={styles.legend} /></span>
                            <input value={context?.formulario.A2_END} onChange={atualizarFormulario} type="text" name="A2_END" placeholder="Rua + número" />
                        </div>

                        <div className={styles.formGroup}>

                            <div className={styles.groupInput}>
                                <span>Bairro <RxDotFilled className={styles.legend} /></span>
                                <input value={context?.formulario.A2_BAIRRO} onChange={atualizarFormulario} type="text" name="A2_BAIRRO" placeholder="" />

                            </div>
                            {/* <div className={styles.groupInput}>
                                <span>Complemento <RxDotFilled className={styles.legend} /></span>
                                <input type="text" name="numero" placeholder="" />
                                <label>digite apenas números</label>
                            </div> */}
                        </div>

                        <div className={styles.formGroup}>

                            <div className={styles.groupInput}>
                                <span>Estado <RxDotFilled className={styles.legend} /></span>
                                <input value={context?.formulario.A2_EST} onChange={atualizarFormulario} type="text" name="A2_EST" placeholder="" />
                                {/* <label>digite apenas números</label> */}
                            </div>
                            <div className={styles.groupInput}>
                                <span>Município <RxDotFilled className={styles.legend} /></span>
                                <input value={context?.formulario.A2_MUN} onChange={atualizarFormulario} type="text" name="A2_MUN" placeholder="" />
                                {/* <label>digite apenas números</label> */}
                            </div>
                        </div>
                    </div>
    )
}