import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { useContext, useRef, useState } from "react"
import { ArquivosContext, FormularioContext } from ".."
import Swal from "sweetalert2"
import { MdDriveFolderUpload } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";

export function Documentos() {

    const  contextForm = useContext(FormularioContext)

    const  context = useContext(ArquivosContext)
   
    const uploadInputRef = useRef<any>();

    async function atualizarDocumentos(event: React.ChangeEvent<HTMLInputElement>) {

        Swal.fire({
            title: "Upload",
            html: "Carregando arquivos...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        await new Promise(r => setTimeout(r, 1000))

        if (event.target.files === null) {
            Swal.fire({
                title: "Upload",
                html: "Erro ao carregar arquivos."
            })
            return
        };

        let arquivos: any = []

        for (let i = 0; i < event.target.files.length; i++) {

            await getBase64(event.target.files[i])
                .then(arquivo => arquivos.push(arquivo))
                .catch(error => {
                    Swal.fire({
                        title: "Upload",
                        html: "Erro ao carregar arquivos."
                    })
                })
        }

        context?.setDocumentos([...context.documentos, ...arquivos])

        event.target.value = ""

        if (Swal.isLoading()) {
            Swal.close()
        }

    }

    function getBase64(file: any): Promise<{ file: string, name: string,}| Error> {

        return new Promise((resolve, reject) => {

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {

                resolve({
                    file: `${reader.result}`,
                    name: file.name
                })

            };
            reader.onerror = function (error) {

                reject(error)

            };

        })

    }

    function removerArquivo(index: number) {

        if(!context?.documentos) {
            return
        }

        let aux = [...context.documentos]

        aux.splice(index, 1)

        context.setDocumentos(aux)
    }

    async function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = event.target.value 
        console.log(nome)
        if(nome ===  "A2_ZLOGO" && event?.target?.files ) {
            
            getBase64(event?.target?.files[0])
            .then( (resposta: any) => {
                
                contextForm?.setFormulario({ ...contextForm.formulario, ...{ [nome]: resposta.file}})
                
            })
            .catch(error => {
                
                Swal.fire("Erro ao carregar logo.")
                event.target.value = ""
            })
            
            return;
        }
        
        
        

        contextForm?.setFormulario({ ...contextForm.formulario, ...{ [nome]: value}})

        console.log(value, nome)

    }

    return (
        <div className={styles.groupCardForm}>
            <div className={styles.formGroup}>
                <div className={styles.groupInput}>

                    <div className={styles.groupInput}>
                        <span>Logomarca</span>
                        <input onChange={atualizarFormulario} type="file"  name="A2_ZLOGO" style={{width: "100%", padding: "10px"}} />
                    </div>

                    <div className={styles.groupInput}>
                        <span>Nº Licença Sanitária/Ambiental<RxDotFilled className={styles.legend} /></span>
                        <input onChange={atualizarFormulario} value={contextForm?.formulario.A2_ZLICENC} name="A2_ZLICENC" type="text" style={{width: "100%"}} />
                    </div>
                    <div className={styles.groupInput}>
                        <span>Certificado de Registro no Conselho <RxDotFilled className={styles.legend} /></span>
                        <input onChange={atualizarFormulario} value={contextForm?.formulario.A2_ZCERTIF} name="A2_ZCERTIF" type="text" style={{width: "100%"}} />
                    </div>
                    <span>Anexar cópia das licenças e certificados.<RxDotFilled className={styles.legend} /></span>
                    <input ref={uploadInputRef} type='file' onChange={atualizarDocumentos} name="A2_ZOBSERV" placeholder="Documentos" multiple style={{ visibility: "hidden", height: 0 }} />
                    <button className={styles.buttonUpload} onClick={() => uploadInputRef?.current?.click()}><MdDriveFolderUpload /> Upload </button>
                    <div>
                        {
                            context?.documentos.map((doc, index) =>

                                <div className={styles.fileRow} key={`${index}file`}>
                                    <h3>{doc.name}</h3>
                                    <button className={styles.removeFileButton} onClick={() => removerArquivo(index)}><FiTrash2 /> Remover</button>
                                </div>
                            )
                        }
                    </div>
                    
                    

                </div>
            </div>
        </div>
    )
}