import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { ChangeEvent, useContext, useState } from "react"
import { FormularioContext } from ".."

export function DadosBasicos() {

    const  context = useContext(FormularioContext)


    function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase()


        if(nome === 'A2_CGC') {
            value = maskaraCnpj(value)
        }


        context?.setFormulario({ ...context.formulario, ...{ [nome]: value}})

    }

    function maskaraCnpj(v: string): string {
        v = v.replace(/\D/g, "")
        v= v.replace(/(\d{14})(\d)/, "$1") //remove numeros que passarem da qunatidade de caracteres do cpf
        v = v.replace(/(\d{2})(\d)/, "$1.$2")       //Coloca um ponto entre o segundo e o terceiro dígito
        v = v.replace(/(\d{3})(\d)/, "$1.$2")       //Coloca um ponto entre o quinto e o sexto dígito
        v = v.replace(/(\d{3})(\d)/, "$1\/$2")       //Coloca uma barra entre o oitavo e o nono dígito
        //de novo (para o segundo bloco de números)
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        return v
    }

    return (
        <>
        <br/>
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>CNPJ <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A2_CGC} type="text" name="A2_CGC" onChange={atualizarFormulario} placeholder="000.000.000-00" />
                <label>digite apenas números</label>
            </div>
            {/* <div className={styles.groupInput}>
                <span>Data de Nascimento <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A2_DTNASC} type="date" name="A2_DTNASC" onChange={atualizarFormulario} placeholder="00/00/0000" />
                <label>digite apenas números</label>
            </div> */}
        </div>
        <div className={styles.groupInput}>
            <span>Razão Social <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A2_NOME} type="text" name="A2_NOME" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        <div className={styles.groupInput}>
            <span>Nome Fantasia <RxDotFilled className={styles.legend} /></span>
            <input maxLength={20} value={context?.formulario.A2_NREDUZ} type="text" name="A2_NREDUZ" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        

        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>Inscrição Estadual</span>
                <input value={context?.formulario.A2_INSCR} type="text" name="A2_INSCR" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
            <div className={styles.groupInput}>
                <span>Inscrição Municipal</span>
                <input  value={context?.formulario.A2_INSCRM} type="text" name="A2_INSCRM" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
        </div>

        <div className={styles.groupInput}>
            <span>Senha <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A2_ZSENHA} type="password" name="A2_ZSENHA" onChange={atualizarFormulario} placeholder="Senha de Acesso" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        </>

            )

}