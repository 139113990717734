import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { useNavigate } from 'react-router-dom';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { GrGrommet, GrRadialSelected } from "react-icons/gr";
import { Dispatch, SetStateAction, createContext, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import Calendar from "react-calendar";
import { DadosBasicos } from "./dados-basicos";
import { Endereco } from "./endereco";
import { Contato } from "./contato";
import { Documentos } from "./documentos";
import { CadastroType, ParceiroDefault } from "../../context/parceiro-cadastro";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { Financeiro } from "./financeiro";
import termosUso from "../../assets/termos.pdf"
import privacidade from "../../assets/privacidade.pdf"

type documentosType = {
    file: any,
    name: any
} 

export const FormularioContext = createContext<{formulario: CadastroType, setFormulario: Dispatch<SetStateAction<CadastroType>>}| null>(null)

export const ArquivosContext = createContext<{documentos: documentosType[], setDocumentos: Dispatch<SetStateAction<documentosType[]>>}| null>(null)

export function CadastroFormulario() {
    const navigate = useNavigate();

    const  [tab, setTab] = useState<'dadosbasicos'|'endereco'|'contato'|'financeiro'|'documentos'>('dadosbasicos')

    const [formulario, setFormulario] = useState(ParceiroDefault);

    const [aceiteTermo, setAceiteTermo] = useState(false);

    const [documentos, setDocumentos] = useState<{
        file: any,
        name: any
    }[]>([])


   

    function login() {
        navigate('/')
    }

    function selectedTab() {

        switch(tab) {

            case 'dadosbasicos':
                return <DadosBasicos/>
            case 'endereco':
                return <Endereco/>
            case 'contato':
                return <Contato/>
            case 'financeiro':
                return <Financeiro/>
            case 'documentos': 
                return <Documentos/>
            default: 
                return <DadosBasicos/>

        }

    }

    function handleNext() {

        if(tab === "dadosbasicos") {
            setTab("endereco")
            return
        }

        if(tab === "endereco") {
            setTab("contato")
            return
        }

        if(tab === "contato") {
            setTab("financeiro")
            return
        }

        if(tab === "financeiro") {
            setTab("documentos")
            return
        }

        if(tab === "documentos") {


            if(documentos.length === 0) {

                Swal.fire({
                    title: "Cadastro!",
                    html: "É necessário enviar documentos de validação."
                })
                return;
            }


            let aux = {...formulario}

            aux.A2_DTNASC = aux.A2_DTNASC?.replaceAll('-', '')
            aux.A2_CEP = aux.A2_CEP.replace(/\D/g, "")
            aux.A2_CGC = aux.A2_CGC.replace(/\D/g, "")


            Swal.fire({
                title: 'Cadastro!',
                html: 'Aguarde, salvando seus dados no sistema.',// add html attribute if you want or remove
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });


            AuthFetchJson(url+ 'INSEREPARCEIRO', {
                body: JSON.stringify( { dados: aux } ),
                method: 'POST'
            })
            .then( async (retorno: any) => {

                if(retorno.sucesso === 'T') {
                    localStorage.setItem("IPEST-TOKEN", retorno.dados[0].TOKEN)

                    await Promise
                    .all(
                        documentos.map(doc =>
                            AuthFetchJson(url + "ENVIADOCUMENTO", {
                                method: "POST",
                                body: JSON.stringify({
                                    PARCEIRO: retorno.dados[0].A2_COD,
                                    LOJA: retorno.dados[0].A2_LOJA,
                                    ARQBASE64: `${doc.file}`.replace(/^.*,/, ""),
                                    NOMEARQ: `${doc.name}`
                                })
                            })

                        )
                    )
                    .then(() => {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Cadastro realizado com sucesso!",
                            showConfirmButton: false,
                            timer: 1500
                          });
                    })
                    .catch(err => {
                        Swal.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "Cadastro realizado com sucesso! Faça login para reenviar seus documentos ou entre contato.",
                            showConfirmButton: true
                          });
                    })

                    
                    
                    navigate('/')

                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao cadastrar cliente, " + retorno.mensagem,
                        showConfirmButton: true
                    });
                }


            })
            .catch((error) => {

                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao cadastrar cliente!" + error,
                    showConfirmButton: true,
                });
                console.log(error)
            }).finally(() => {


                if(Swal.isLoading()) {
                    Swal.close()
                }

            })

        }

    }

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <img alt="logo" src={logo} className={styles.logo} />
                <h1 className={styles.title}>Cadastro</h1>
            </div>

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>


            <div className={styles.content}>

                <div className={styles.cardForm}>
                    <nav className={styles.menuSup}>
                        <p 
                            onClick={() => setTab('dadosbasicos')}
                            className={tab === 'dadosbasicos' ? styles.menuSupSelected : ''}>Dados Básicos</p>
                        <p 
                            onClick={() => setTab('endereco')}
                            className={tab === 'endereco' ? styles.menuSupSelected : ''}>Endereço</p>
                        <p 
                            onClick={() => setTab('contato')}
                            className={tab === 'contato' ? styles.menuSupSelected : ''}>Contato</p>
                        <p 
                            onClick={() => setTab('financeiro')}
                            className={tab === 'financeiro' ? styles.menuSupSelected : ''}>Financeiro</p>
                        <p 
                            onClick={() => setTab('documentos')}
                            className={tab === 'documentos' ? styles.menuSupSelected : ''}>Documentos</p>
                    </nav>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <FormularioContext.Provider value={{formulario, setFormulario}}>
                    <ArquivosContext.Provider value={{documentos, setDocumentos}}>

                    {
                        selectedTab()
                    }
                    </ArquivosContext.Provider>
                    </FormularioContext.Provider>
                
                    <hr />
                    

                    {
                        tab === "documentos" &&
                        <div className={styles.termoContainer}>
                            <div className={styles.radioGroup}>
                                <input title="termo" type="radio" name="termo" onClick={() => setAceiteTermo(!aceiteTermo)} checked={aceiteTermo} />
                                <span> Eu aceito os </span>
                                <a rel="noreferrer" href={termosUso} target="_blank">Termos de Uso</a>
                                <span> e </span>
                                <a rel="noreferrer" href={privacidade} target="_blank">Políticas de Privacidade.</a>
                            </div>
                        </div>
                    }

                    
                    <div className={aceiteTermo ? styles.submitNextSelected : styles.submitNext}>
                        <button onClick={handleNext}>
                            <span>Confirmar e finalizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>

            </div>


        </div>
    )
}