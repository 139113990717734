import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { ChangeEvent, useContext, useEffect, useState } from "react"
import { FormularioContext } from ".."
import { Utils } from "../../../utils"
import { url } from "../../../api"
import Swal from "sweetalert2"
import { ContextRegister } from "../../../context"

export function DadosBasicos() {

    const  context = useContext(FormularioContext)
    const { state, dispatch } = useContext(ContextRegister);

    function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase() 


        if(nome === 'A2_CGC') {
            value = Utils.maskaraCnpj(value)
        }


        context?.setFormulario({ ...context.formulario, ...{ [nome]: value}})

    }


    

    

    return (
        <>
        <br/>
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>CNPJ</span>
                <input value={context?.formulario.A2_CGC} type="text" name="A2_CGC" onChange={atualizarFormulario} placeholder="000.000.000-00" disabled/>
            </div>
            {/* <div className={styles.groupInput}>
                <span>Data de Nascimento <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A2_DTNASC} type="date" name="A2_DTNASC" onChange={atualizarFormulario} placeholder="00/00/0000" />
                <label>digite apenas números</label>
            </div> */}
        </div>
        <div className={styles.groupInput}>
            <span>Razão Social <RxDotFilled className={styles.legend} /></span>
            <input value={context?.formulario.A2_NOME} type="text" name="A2_NOME" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        <div className={styles.groupInput}>
            <span>Nome Fantasia <RxDotFilled className={styles.legend} /></span>
            <input maxLength={20} value={context?.formulario.A2_NREDUZ} type="text" name="A2_NREDUZ" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        

        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>Inscrição Estadual</span>
                <input value={context?.formulario.A2_INSCR} type="text" name="A2_INSCR" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
            <div className={styles.groupInput}>
                <span>Inscrição Municipal <RxDotFilled className={styles.legend} /></span>
                <input  value={context?.formulario.A2_INSCRM} type="text" name="A2_INSCRM" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
        </div>

        <div className={styles.groupInput}>
            <span>Senha (preencha apenas se desejar trocar sua senha)</span>
            <input value={context?.formulario?.A2_ZSENHA} type="password" name="A2_ZSENHA" onChange={atualizarFormulario} placeholder="Senha de Acesso" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        <div className={styles.groupInput}>
            <span>Confirmar Senha </span>
            <input value={context?.formulario?.A2_ZSENHA_CONFIRM} type="password" name="A2_ZSENHA_CONFIRM" onChange={atualizarFormulario} placeholder="Confirmar senha de Acesso" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        </>

            )

}