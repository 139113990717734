import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { useContext, useRef, useState } from "react"
import { ArquivosContext } from ".."
import Swal from "sweetalert2"
import { MdDriveFolderUpload } from "react-icons/md";
import { FiTrash2 } from "react-icons/fi";

export function Documentos() {

    const  context = useContext(ArquivosContext)
   
    const uploadInputRef = useRef<any>();

    async function atualizarDocumentos(event: React.ChangeEvent<HTMLInputElement>) {

        Swal.fire({
            title: "Upload",
            html: "Carregando arquivos...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        await new Promise(r => setTimeout(r, 1000))

        if (event.target.files === null) {
            Swal.fire({
                title: "Upload",
                html: "Erro ao carregar arquivos."
            })
            return
        };

        let arquivos: any = []

        for (let i = 0; i < event.target.files.length; i++) {

            await getBase64(event.target.files[i], arquivos)
                .then(arquivo => arquivos.push(arquivo))
                .catch(error => {
                    Swal.fire({
                        title: "Upload",
                        html: "Erro ao carregar arquivos."
                    })
                })
        }

        context?.setDocumentos([...context.documentos, ...arquivos])

        event.target.value = ""

        if (Swal.isLoading()) {
            Swal.close()
        }

    }

    function getBase64(file: any, arquivos: any[]) {

        return new Promise((resolve, reject) => {


            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {

                console.log(reader.result)

                resolve({
                    file: reader.result,
                    name: file.name
                })



            };
            reader.onerror = function (error) {

                reject(error)

            };

        })

    }

    function removerArquivo(index: number) {

        if(!context?.documentos) {
            return
        }

        let aux = [...context.documentos]

        aux.splice(index, 1)

        context.setDocumentos(aux)
    }

    return (
        <div className={styles.groupCardForm}>
            <div className={styles.formGroup}>
                <div className={styles.groupInput}>
                    <span>Documentos <RxDotFilled className={styles.legend} /></span>
                    <input ref={uploadInputRef} type='file' onChange={atualizarDocumentos} name="A2_ZOBSERV" placeholder="Documentos" multiple style={{ visibility: "hidden" }} />
                    <button className={styles.buttonUpload} onClick={() => uploadInputRef?.current?.click()}><MdDriveFolderUpload /> Upload </button>
                    <div>
                        {
                            context?.documentos.map((doc, index) =>

                                <div className={styles.fileRow} key={`${index}file`}>
                                    <h3>{doc.name}</h3>
                                    <button className={styles.removeFileButton} onClick={() => removerArquivo(index)}><FiTrash2 /> Remover</button>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}