import styles from "./styles.module.css"
import { useNavigate } from 'react-router-dom';
import { FormEvent, ReactNode, useContext, useEffect, useState } from "react";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";

import { Menu } from "../menu";
import { Modal } from "../../component";
import { Modal as ModalAvaliacao } from "../../component/modal"
import { InputFiles } from "typescript";
import { STATUS_SERVICO } from "../../constants";
import { FaFileInvoice } from "react-icons/fa";
import { MdOutlineStarOutline, MdOutlineStarPurple500 } from "react-icons/md";
import { PiShieldWarningFill } from "react-icons/pi";
import { FaClipboard, FaHeadSideMask } from "react-icons/fa6";
import { Utils } from "../../utils";
import { formatDate } from "react-calendar/dist/cjs/shared/dateFormatter";

export function MeusServicos() {

    const { state: { usuario } } = useContext(ContextRegister)
    const [tecnicos, setTecnicos] = useState([])
    const [modalVincularTecnico, setModalVincularTecnico] = useState(false)
    const [modalVincularTecnicoRetorno, setModalVincularTecnicoRetorno] = useState(false)
    const [modalAgendarAssistencia, setModalAgendarAssistencia] = useState(false)
    const [modalUplodaNF, setModalUploadNF] = useState(false)
    const navigate = useNavigate();
    const [servicos, setServicos] = useState([])

    const [tecnicoSelecionado, setTecnicoSelecionado] = useState("")
    const [horaSelecionada, setHoraSelecionada] = useState("00:00")
    const [dataSelecionada, setDataSelecionada] = useState("")
    const [servicoSelecionado, setServicoSelecionado] = useState<any>(null)


    const [formNfe, setFormNfe] = useState<any>({ "NOTA.PDF": "", "NOTA.XML": "" })

    const horariosTurno: any = {
        "M": {
            inicio: "08",
            fim: "12"
        },
        "T": {

            inicio: "13",
            fim: "17"
        },
        "N": {

            inicio: "18",
            fim: "22"
        },
    }

    const turno: any = {
        "M": "Manhã",
        "T": "Tarde",
        "N": "Noite",
    }


    const formaterCurrency = new Intl.NumberFormat('pt-br', {
        currency: 'BRL',
        style: 'currency'
    })

    const [showModal, setShowModal] = useState(false)



    function mediaAvaliacao(av1: any, av2: any, av3: any) {

        let total = parseInt(`${av1}`) + parseInt(`${av2}`) + parseInt(`${av3}`)

        total = total / 3

        return Math.trunc(total)

    }

    function handleAvaliacao(servico: any) {

        if (servico.Z1_STATUS !== "5") {

            Swal.fire({
                title: "Avaliação",
                text: "Apenas serviços finalizados podem ser avaliados!",
            })

            return;
        }

        setServicoSelecionado(servico)
        setShowModal(true)
    }


    function starts(avaliacao: number): ReactNode[] {


        let whiteStars = 5 - avaliacao

        let fullStars = avaliacao


        let icons: ReactNode[] = []

        for (let i = 0; i < fullStars; i++) {
            icons.push(<MdOutlineStarPurple500 fontSize={18} color="var(--warning-dark)" />)
        }

        for (let i = 0; i < whiteStars; i++) {
            icons.push(<MdOutlineStarOutline fontSize={18} />)
        }

        return icons

    }


    function dateFormater(dataStr: string) {


        if (!dataStr) {
            return ""
        }

        //dataStr AAAAMMDD
        return new Date(`${dataStr.slice(0, 4)}-${dataStr.slice(4, 6)}-${dataStr.slice(6, 8)} 00:00`).toLocaleString('pr-BR', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })

    }

    async function buscarTecnicos() {

        return AuthFetchJson(`${url}COLETAFORNECEDOR`, {
            method: "POST",
            body: JSON.stringify(
                {
                    TECFOR: "S",
                    PARCEIRO: usuario.A2_COD,
                    LOJA: usuario.A2_LOJA
                })
        })
            .then((retorno: any) => {
                if (retorno.sucesso !== 'T') {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao buscar os servicos!" + retorno.mensagem,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    return;
                }

                if (Swal.isLoading()) {
                    Swal.close()
                }


                setTecnicos(retorno.dados)

            })
            .catch(error => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao buscar dados!" + error,
                    showConfirmButton: false,
                    timer: 1500
                });
            })
    }

    async function buscarServicos() {

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados dos serviços.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(`${url}COLETAATENDIMENTO`, {
            method: "POST",
            body: JSON.stringify(
                {
                    PARCEIRO: usuario.A2_COD,
                    LOJA: usuario.A2_LOJA,
                    TIPO: "P",
                    STATU: "2"
                })
        })
            .then(async (retorno: any) => {
                if (retorno.sucesso !== 'T') {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao buscar os servicos!" + retorno.mensagem,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    return;
                }

                if (Swal.isLoading()) {

                    await buscarTecnicos();
                    Swal.close()
                }


                setServicos(retorno.dados)


            })
            .catch(error => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao buscar dados!" + error,
                    showConfirmButton: false,
                    timer: 1500
                });
            })
    }

    async function pegarServico(servico: any) {

        setServicoSelecionado(servico)
        let selectValues: any = {}
        tecnicos.map((t: any) => { selectValues[t.A2_COD] = t.A2_NOME })
        setModalVincularTecnico(true)

    }

    async function pegarRetornoServico(servico: any) {

        setServicoSelecionado(servico)

        let selectValues: any = {}

        tecnicos.map((t: any) => { selectValues[t.A2_COD] = t.A2_NOME })


        setModalVincularTecnicoRetorno(true)

    }

    function horasInvalidas(turno: string): boolean {


        let aux: any = horaSelecionada
        aux = aux.split(":")

        if (parseInt(aux[0]) < parseInt(horariosTurno[turno].inicio) ||
            parseInt(aux[0]) >= parseInt(horariosTurno[turno].fim)
        ) {

            return true

        }

        return false

    }

    function sendNfe(event: FormEvent) {

        event.preventDefault()

        if (formNfe["NOTA.PDF"] === "" && formNfe["NOTA.XML"] === "") {
            return
        }

        let keys: any = Object.keys(formNfe)


        Promise.all(keys.map(
            (k: string) => {

                if (formNfe[k] === "") {
                    return []
                }

                return AuthFetchJson(`${url}ENVIANOTACLI`, {
                    method: "POST",
                    body: JSON.stringify({
                        NUMPV: servicoSelecionado.Z1_NUMPV,
                        ARQBASE64: formNfe[k],
                        NOMEARQ: k
                    })
                }).then(response => response.json())

            }
        ))
            .then(response => {

                response.map((r: any) => {
                    if (r.sucesso !== "T") {
                        throw new Error(r.mensagem)
                    }
                })

                Swal.fire("Upload realizado com sucesso!")
                setModalUploadNF(false)

            })
            .catch(err => {
                Swal.fire("Erro ao fazer upload dos arquivos, tente novamente ou entre em contato com o administrador.")
                console.log(err)
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })
    }

    function onChangeArq(event: React.ChangeEvent<HTMLInputElement>) {

        if (!event?.target?.files) {
            return;
        }

        let [name, ext] = event.target.name.split(".")

        if (event.target.files[0].type.search(ext.toLowerCase()) === -1) {
            Swal.fire("Arquivo incompatível.")
            event.target.value = ""
            return
        }

        getBase64(event.target.files[0])
            .then((retorno: any) => {
                setFormNfe((nf: any) => (
                    {
                        ...nf,
                        [event.target.name]: retorno.file
                    }
                ))
            })
            .catch(err => {
                console.log(err)
            })
    }


    function getBase64(file: any): Promise<{ file: string, name: string, } | Error> {

        return new Promise((resolve, reject) => {

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {

                resolve({
                    file: `${reader.result}`,
                    name: file.name
                })

            };
            reader.onerror = function (error) {

                reject(error)

            };

        })

    }

    function vincularTecnicoRetorno(event: any) {

        event.preventDefault();

        if (tecnicoSelecionado === "") {
            Swal.fire({
                title: "É necessário informar o técnico responsável",
                timer: 1500
            })
            return;
        }


        if (horasInvalidas(servicoSelecionado.Z1_TURNORE)) {


            Swal.fire({
                title: "Horas inválidas",
                timer: 1500
            })
            return;
        }

        Swal.fire({
            title: 'Aguarde!',
            html: 'Vinculando o seviço.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        const [A2_COD, A2_LOJA] = tecnicoSelecionado.split("|")


        AuthFetchJson(`${url}PARCEIRORETORNO`, {
            method: "POST",
            body: JSON.stringify({
                DADOS: {
                    "Z1_NUMPV": servicoSelecionado.Z1_NUMPV,
                    "DTRETOR": servicoSelecionado.Z1_DTRETOR,
                    "HRRETOR": horaSelecionada,
                    "TECNICO": A2_COD,
                    "LOJA": A2_LOJA,
                }
            })
        })
            .then(retorno => {


                if (retorno.sucesso != "T") {
                    Swal.fire({
                        title: "Pegar Serviço",
                        text: "Erro ao pegar o serviço!",
                        icon: "error",
                        timer: 1500,
                        showConfirmButton: false
                    })
                } else {

                    Swal.fire({
                        title: "Pegar Serviço",
                        text: "Serviço selecionado com sucesso!",
                        icon: "success",
                        timer: 1500,
                        showConfirmButton: false
                    })


                    buscarServicos()


                }
            }).catch(error => {
                console.log(error)
                Swal.fire({
                    title: "Pegar Serviço",
                    text: "Erro ao pegar  serviço!",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false
                })
            }).finally(() => {
                setModalVincularTecnicoRetorno(false)
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })

    }

    function vincularTecnico(event: any) {

        event.preventDefault();

        if (tecnicoSelecionado === "") {
            Swal.fire({
                title: "É necessário informar o técnico responsável",
                timer: 1500
            })
            return;
        }


        if (horasInvalidas(servicoSelecionado.Z1_TURNO)) {


            Swal.fire({
                title: "Horas inválidas",
                timer: 1500
            })
            return;
        }

        Swal.fire({
            title: 'Aguarde!',
            html: 'Vinculando o seviço.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        const [A2_COD, A2_LOJA] = tecnicoSelecionado.split("|")


        AuthFetchJson(`${url}INSEREVISITA`, {
            method: "POST",
            body: JSON.stringify({
                DADOS: {
                    "Z1_PARCEIR": usuario.A2_COD,
                    "Z1_LOJA": usuario.A2_LOJA,
                    "Z1_FILIAL": servicoSelecionado.Z1_FILIAL,
                    "Z1_NUMPV": servicoSelecionado.Z1_NUMPV,
                    "Z1_DTVISIT": servicoSelecionado.Z1_DTAGEND,
                    "Z1_HRVISIT": horaSelecionada,
                    "Z1_TECNICO": A2_COD,
                    "Z1_LOJTEC": A2_LOJA
                }
            })
        }).then(retorno => {


            if (retorno.sucesso != "T") {
                Swal.fire({
                    title: "Pegar Serviço",
                    text: "Erro ao pegar o serviço!",
                    icon: "error",
                    timer: 1500,
                    showConfirmButton: false
                })
            } else {

                Swal.fire({
                    title: "Pegar Serviço",
                    text: "Serviço selecionado com sucesso!",
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false
                })


                buscarServicos()


            }
        }).catch(error => {
            console.log(error)
            Swal.fire({
                title: "Pegar Serviço",
                text: "Erro ao pegar  serviço!",
                icon: "error",
                timer: 1500,
                showConfirmButton: false
            })
        }).finally(() => {
            setModalVincularTecnico(false)
            if (Swal.isLoading()) {
                Swal.close()
            }
        })
    }

    function handleUploadNF(service: any) {
        setServicoSelecionado(service)
        setModalUploadNF(true)
    }

    function nomeTecnico(A2_COD: string) {

        if (!A2_COD) return;

        let tecnico: any = tecnicos.filter((tec: any) => tec.A2_COD === A2_COD)
        return tecnico[0].A2_NOME.substring(0, 20)
    }


    function handleAgendarAssistencia(service: any) {
        setServicoSelecionado(service)
        setDataSelecionada(Utils.formatDtProtheusToInput(service.Z1_DTASSIS))
        setModalAgendarAssistencia(true)
    }

    function aguardandoTecnico(service: any): boolean {

        if (service.Z1_TECNICO === "") {
            return true
        }

        if (service.Z1_STASSIS === "S" && service.Z1_HRASSIS === "") {
            return true
        }

        if (service.Z1_TECNIC3 === "" && service.Z1_DTRETOR !== "") {
            return true
        }

        return false
    }

    function agendarAssistencia(event: FormEvent) {

        event.preventDefault()

        Swal.fire({
            title: "Agendamento Assistênia",
            html: "Aguarde enquanto realizamos o agendamento!",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        const [A2_COD, A2_LOJA] = tecnicoSelecionado.split("|")

        AuthFetchJson(`${url}PARCEIROASSISTENCIA`, {
            method: "POST",
            body: JSON.stringify({
                DADOS: {
                    Z1_NUMPV: servicoSelecionado.Z1_NUMPV,
                    LOJA: usuario.A2_LOJA,
                    DTASSIS: Utils.formatDTtoAAAAMMDD(new Date(`${dataSelecionada} 00:00`)),
                    HRASSIS: horaSelecionada,
                    TECNICO: A2_COD
                }
            })
        })
            .then(resposta => {

                if (resposta.sucesso !== "T") {

                    Swal.fire("Erro ao agendar assistência, tente novamente ou entre em contato com o administrador. " + resposta.mensagem)
                    return
                }

                setModalAgendarAssistencia(false);
                buscarServicos()

            })
            .catch(err => {
                console.log(err)
                Swal.fire("Erro ao agendar assistência, tente novamente ou entre em contato com o administrador. ")
            }).finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })

    }

    useEffect(() => {
        buscarServicos()
    }, [])

    return (

        <div className={styles.container}>

            {
                showModal &&
                <ModalAvaliacao setShowModal={setShowModal}>


                    <h3>Pontualidade</h3>
                    <p className={styles.action}>
                        {
                            starts(servicoSelecionado?.Z1_AVALIAC)
                                .map((star, index) =>
                                (<span key={`${index}01`}>{star}
                                </span>))}
                    </p>

                    <h3>Asseio e Organização</h3>
                    <p className={styles.action}>
                        {starts(servicoSelecionado?.Z1_AVALIA2).map((star, index) => (<span key={`${index}02`}>{star}</span>))}
                    </p>
                    <h3>Eficiência do Controle</h3>
                    <p className={styles.action}>
                        {starts(servicoSelecionado?.Z1_AVALIA3).map((star, index) => (<span key={`${index}03`}>{star}</span>))}
                    </p>



                </ModalAvaliacao>
            }


            <Menu />

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", width: "100%", paddingRight: 30, paddingTop: 10 }}>

                <span
                    style={{ width: 8, height: 8, backgroundColor: "var(--warning-lighttest)", borderRadius: 4 }}
                ></span>
                &nbsp;
                <span> &nbsp;Aguardando técnico</span>

            </div>

            <div className={styles.content} >
                <table>
                    <thead>
                        <tr>
                            <th>OS</th>
                            <th>Estado</th>
                            <th>Cidade</th>
                            <th>Bairro</th>
                            <th>Data Agendada</th>
                            <th>Data Retorno</th>
                            <th>Data Assistência</th>
                            <th>Pragas</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Técnico</th>
                            <th>Avaliações</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            servicos.map((servico: any) => (
                                <tr key={servico.Z1_NUMPV} style={aguardandoTecnico(servico) ? { backgroundColor: "var(--warning-lighttest)" } : {}}>
                                    <td>{servico.Z1_NUMPV}</td>
                                    <td>{servico.Z1_EST}</td>
                                    <td>{servico.Z1_MUN}</td>
                                    <td>{servico.Z1_BAIRRO}</td>
                                    <td>{dateFormater(servico.Z1_DTAGEND)} <br /> {servico.Z1_HRVISIT ? servico.Z1_HRVISIT : turno[servico.Z1_TURNO]}</td>
                                    <td>{dateFormater(servico.Z1_DTRETOR)} <br /> {servico.Z1_HRRETOR ? servico.Z1_HRRETOR : turno[servico.Z1_TURNORE]}</td>
                                    <td>{dateFormater(servico.Z1_DTASSIS)} <br /> {servico.Z1_HRASSIS ? servico.Z1_HRASSIS : turno[servico.Z1_TURNOAS]}</td>

                                    <td>{servico.PRAGAS.map((p: any) => p.DESCRICAO).join(', ')}</td>
                                    <td>{formaterCurrency.format(servico.VRTOTAL)}</td>
                                    <td>{STATUS_SERVICO[servico.Z1_STATUS]}</td>
                                    <td className={styles.action}>{nomeTecnico(servico.Z1_TECNICO)}</td>
                                    <td className={styles.action}>{servico.Z1_STATUS == "5" && starts(mediaAvaliacao(servico.Z1_AVALIAC, servico.Z1_AVALIA2, servico.Z1_AVALIA3)).map((star, index) => (<span onClick={() => handleAvaliacao(servico)}>{star}</span>))}</td>
                                    <td className={styles.action} style={{ width: "80px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        {(servico.Z1_STATUS === "3" || servico.Z1_STATUS === "2") &&
                                            <FaHeadSideMask color="var(--brand1-light)" title="Definir Técnico" onClick={() => pegarServico(servico)} />
                                        }

                                        {(servico.Z1_DTRETOR !== "" && servico.Z1_STATUS < "5") &&
                                            <FaHeadSideMask color="var(--brand2-light)" title="Definir Técnico Retorno" onClick={() => pegarRetornoServico(servico)} />
                                        }

                                        {

                                            servico.Z1_STATUS === "5" &&
                                            <FaFileInvoice title="Enviar Nota Fiscal" onClick={() => handleUploadNF(servico)} />

                                        }

                                        {
                                            servico.Z1_STASSIS === "S" &&
                                            <PiShieldWarningFill size={17} onClick={() => handleAgendarAssistencia(servico)} title="Marcar Assistência" color="var(--negative-medium)" />
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
            {modalVincularTecnico &&
                <form onSubmit={vincularTecnico}>
                    <Modal titulo="Vincular Técnico" fechar={() => setModalVincularTecnico(false)}>
                        <div className={styles.groupInput}>

                            <h4 style={{ textAlign: "end" }}>OS {servicoSelecionado.Z1_NUMPV} {dateFormater(servicoSelecionado.Z1_DTAGEND)}</h4>

                            <label>Técnico</label>

                            <select
                                value={tecnicoSelecionado}
                                onChange={(event) => { setTecnicoSelecionado(event.currentTarget.value) }}
                            >
                                <option value="">Selecione um Técnico</option>
                                {
                                    tecnicos.map((tecnico: any) => (
                                        <option key={tecnico.A2_COD} value={`${tecnico.A2_COD}|${tecnico.A2_LOJA}`}>{tecnico.A2_NOME}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className={styles.groupInput}>

                            <label>Horário ({servicoSelecionado?.Z1_TURNO ? horariosTurno[servicoSelecionado?.Z1_TURNO].inicio : "0"}hrs até as {servicoSelecionado?.Z1_TURNO ? horariosTurno[servicoSelecionado?.Z1_TURNO].fim : "23:59"}hrs)</label>

                            <input
                                type="time"
                                min={servicoSelecionado?.Z1_TURNO ? horariosTurno[servicoSelecionado?.Z1_TURNO].inicio : "0"}
                                max={servicoSelecionado?.Z1_TURNO ? horariosTurno[servicoSelecionado?.Z1_TURNO].fim : "23:59"}
                                value={horaSelecionada}
                                onChange={event => setHoraSelecionada(event.target.value)}
                            />

                        </div>
                    </Modal>
                </form>
            }

            {modalVincularTecnicoRetorno &&
                <form onSubmit={vincularTecnicoRetorno}>
                    <Modal titulo="Vincular Técnico Retorno" fechar={() => setModalVincularTecnicoRetorno(false)}>
                        <div className={styles.groupInput}>

                            <h4 style={{ textAlign: "end" }}>OS {servicoSelecionado.Z1_NUMPV} {dateFormater(servicoSelecionado.Z1_DTRETOR)}</h4>

                            <label>Técnico</label>

                            <select
                                value={tecnicoSelecionado}
                                onChange={(event) => { setTecnicoSelecionado(event.currentTarget.value) }}
                            >
                                <option value="">Selecione um Técnico</option>
                                {
                                    tecnicos.map((tecnico: any) => (
                                        <option key={tecnico.A2_COD} value={`${tecnico.A2_COD}|${tecnico.A2_LOJA}`}>{tecnico.A2_NOME}</option>
                                    ))
                                }
                            </select>
                        </div>

                        <div className={styles.groupInput}>

                            <label>Horário ({servicoSelecionado?.Z1_TURNORE ? horariosTurno[servicoSelecionado?.Z1_TURNORE].inicio : "0"}hrs até as {servicoSelecionado?.Z1_TURNORE ? horariosTurno[servicoSelecionado?.Z1_TURNORE].fim : "23:59"}hrs)</label>

                            <input
                                type="time"
                                min={servicoSelecionado?.Z1_TURNORE ? horariosTurno[servicoSelecionado?.Z1_TURNORE].inicio : "0"}
                                max={servicoSelecionado?.Z1_TURNORE ? horariosTurno[servicoSelecionado?.Z1_TURNORE].fim : "23:59"}
                                value={horaSelecionada}
                                onChange={event => setHoraSelecionada(event.target.value)}
                            />

                        </div>
                    </Modal>
                </form>
            }

            {modalAgendarAssistencia &&
                <form onSubmit={agendarAssistencia}>
                    <Modal titulo="Agendar Assitência" fechar={() => setModalAgendarAssistencia(false)}>
                        <div className={styles.groupInput}>

                            <label>Data</label>

                            <input
                                type="date"
                                value={dataSelecionada}
                                onChange={event => setDataSelecionada(event.target.value)}
                                required />

                        </div>
                        <div className={styles.groupInput}>

                            <label>Horário ({servicoSelecionado?.Z1_TURNOAS ? horariosTurno[servicoSelecionado?.Z1_TURNOAS].inicio : "0"}hrs até as {servicoSelecionado?.Z1_TURNOAS ? horariosTurno[servicoSelecionado?.Z1_TURNOAS].fim : "23:59"}hrs)</label>

                            <input
                                type="time"
                                value={horaSelecionada}
                                onChange={event => setHoraSelecionada(event.target.value)}
                                required />

                        </div>

                        <div className={styles.groupInput}>

                            <label>Técnico</label>

                            <select
                                value={tecnicoSelecionado}
                                onChange={(event) => { setTecnicoSelecionado(event.currentTarget.value) }}
                                required>
                                <option value="">Selecione um Técnico</option>
                                {
                                    tecnicos.map((tecnico: any) => (
                                        <option key={tecnico.A2_COD} value={`${tecnico.A2_COD}|${tecnico.A2_LOJA}`}>{tecnico.A2_NOME}</option>
                                    ))
                                }
                            </select>
                        </div>


                    </Modal>
                </form>
            }

            {
                modalUplodaNF &&
                <form onSubmit={sendNfe}>
                    <Modal titulo="Enviar Nota Fiscal" fechar={() => setModalUploadNF(false)}>
                        <div className={styles.groupInput}>

                            <label>Danfe PDF</label>

                            <input type="file" onChange={onChangeArq} accept="application/pdf" name="NOTA.PDF" style={{ padding: "10px" }} />
                        </div>

                        <div className={styles.groupInput}>

                            <label>XML NF</label>

                            <input type="file" onChange={onChangeArq} accept="application/xml" name="NOTA.XML" style={{ padding: "10px" }} />

                        </div>
                    </Modal>
                </form>
            }
        </div>

    )

}