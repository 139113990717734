export type CadastroType = {
    "A2_ZTIPFOR": "P"|"T",
    "A2_TIPO": "J" | "F"
    "A2_CGC": string
    "A2_CEP": string
    "A2_NOME": string
    "A2_END": string
    "A2_BAIRRO": string
    "A2_MUN": string
    "A2_COMPLEM"?: string
    "A2_EST": string
    "A2_TEL"?: string
    "A2_CONTATO"?: string
    "A2_DTNASC"?: string //"AAAAMMDD"
    "A2_EMAIL": string
    "A2_ZOBSERV"?: string
    "A2_NREDUZ": string
    "A2_ZSENHA"?: string
    "A2_ZSENHA_CONFIRM"?: string
    "A2_INSCR"?: string
    "A2_INSCRM"?: string
    "A2_ZLOGO"?: string
    "A2_ZLICENC"?: string
    "A2_ZCERTIF"?: string
    "A2_ZRESPON"?: string
    "A2_ZCONSPR"?: string
    "A2_BANCO": string
    "A2_AGENCIA": string
    "A2_NUMCON": string
    "A2_ZPIX": string
    "A2_DVAGE"?: string
    "A2_DVCTA"?: string


}



export const ParceiroDefault: CadastroType = {
    "A2_ZTIPFOR": "P",
    "A2_TIPO": "J",
    "A2_CGC": "",
    "A2_CEP": "",
    "A2_NOME": "",
    "A2_END": "",
    "A2_BAIRRO": "",
    "A2_MUN": "",
    "A2_COMPLEM": "",
    "A2_EST": "",
    "A2_TEL": "",
    "A2_CONTATO": "",
    "A2_DTNASC": "",
    "A2_EMAIL": "",
    "A2_ZOBSERV": "",
    "A2_NREDUZ": "",
    "A2_ZSENHA": "",
    "A2_INSCR": "",
    "A2_INSCRM": "",
    "A2_ZLOGO": "",
    "A2_ZLICENC": "",
    "A2_ZCERTIF": "",
    "A2_ZRESPON": "",
    "A2_ZCONSPR": "",
    "A2_BANCO": "",
    "A2_AGENCIA": "",
    "A2_NUMCON": "",
    "A2_ZPIX": "",
    "A2_DVAGE": "",
    "A2_DVCTA": ""
}

export const TecnicoDefault: CadastroType = {
    "A2_ZTIPFOR": "T",
    "A2_TIPO": "F",
    "A2_CGC": "",
    "A2_CEP": "",
    "A2_NOME": "",
    "A2_END": "",
    "A2_BAIRRO": "",
    "A2_MUN": "",
    "A2_COMPLEM": "",
    "A2_EST": "",
    "A2_TEL": "",
    "A2_CONTATO": "",
    "A2_DTNASC": "",
    "A2_EMAIL": "",
    "A2_ZOBSERV": "",
    "A2_NREDUZ": "",
    "A2_ZSENHA": "",
    "A2_INSCR": "",
    "A2_INSCRM": "",
    "A2_BANCO": "",
    "A2_AGENCIA": "",
    "A2_NUMCON": "",
    "A2_ZPIX": "",
    "A2_DVAGE": "",
    "A2_DVCTA": ""
}
