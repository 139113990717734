import { Menu } from "../menu";
import styles from "./styles.module.css"
import { useNavigate } from 'react-router-dom';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import { DadosBasicos } from "./dados-basicos";
import { Endereco } from "./endereco";
import { Contato } from "./contato";
import { Documentos } from "./documentos";
import { CadastroType, ParceiroDefault } from "../../context/parceiro-cadastro";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";
import { Utils } from "../../utils";
import { Financeiro } from "./financeiro";


type documentosType = {
    file: any,
    name: any
}

export const FormularioContext = createContext<{ formulario: CadastroType, setFormulario: Dispatch<SetStateAction<CadastroType>> } | null>(null)

export const ArquivosContext = createContext<{ documentos: documentosType[], setDocumentos: Dispatch<SetStateAction<documentosType[]>> } | null>(null)

export function MinhaConta() {
    const navigate = useNavigate();

    const [tab, setTab] = useState<'dadosbasicos' | 'endereco' | 'contato' | 'financeiro' | 'documentos'>('dadosbasicos')

    const [formulario, setFormulario] = useState(ParceiroDefault);

    const { state, dispatch } = useContext(ContextRegister);

    const [documentos, setDocumentos] = useState<{
        file: any,
        name: any
    }[]>([])


    function selectedTab() {

        switch (tab) {

            case 'dadosbasicos':
                return <DadosBasicos />
            case 'endereco':
                return <Endereco />
            case 'contato':
                return <Contato />
            case 'financeiro':
                return <Financeiro />
            case 'documentos':
                return <Documentos />
            default:
                return <DadosBasicos />

        }

    }

    function handleNext() {

        if (tab === "dadosbasicos") {
            setTab("endereco")
            return
        }

        if (tab === "endereco") {
            setTab("contato")
            return
        }

        if (tab === "contato") {
            setTab("financeiro")
            return
        }

        if (tab === "financeiro") {
            setTab("documentos")
            return
        }

        if (tab !== "documentos") {
            return
        }


        
        
        let aux = { ...formulario }
        
        
        if(aux.A2_ZSENHA && aux.A2_ZSENHA.length > 0 && aux.A2_ZSENHA !== aux.A2_ZSENHA_CONFIRM) {
            Swal.fire("Confirmação de senha não confere!")
            return
        }
        
        if (!aux.A2_ZSENHA) {
            delete aux.A2_ZSENHA_CONFIRM
            delete aux.A2_ZSENHA
        }

        aux.A2_DTNASC = aux.A2_DTNASC?.replaceAll('-', '')
        aux.A2_CEP = aux.A2_CEP.replace(/\D/g, "")
        aux.A2_CGC = aux.A2_CGC.replace(/\D/g, "")

        Swal.fire({
            title: 'Cadastro!',
            html: 'Aguarde, salvando seus dados no sistema.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(url + 'ALTERAPARCEIRO', {
            body: JSON.stringify({ dados: aux }),
            method: 'POST'
        })
            .then(async (retorno: any) => {

                if (retorno.sucesso === 'T') {

                    await Promise
                        .all(
                            documentos.map(doc =>

                                AuthFetchJson(url + "ENVIADOCUMENTO", {
                                    method: "POST",
                                    body: JSON.stringify({
                                        PARCEIRO: retorno.dados[0].A2_COD,
                                        LOJA: retorno.dados[0].A2_LOJA,
                                        ARQBASE64: `${doc.file}`.replace(/^.*,/, ""),
                                        NOMEARQ: `${doc.name}`
                                    })
                                })

                            )
                        )
                        .then(() => {
                            Swal.fire({
                                position: "top-end",
                                icon: "success",
                                title: "Cadastro realizado com sucesso!",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        })
                        .catch(err => {
                            Swal.fire({
                                position: "top-end",
                                icon: "warning",
                                title: "Cadastro realizado com sucesso! Faça login para reenviar seus documentos ou entre contato.",
                                showConfirmButton: true
                            });
                        })



                    getDadosParceiro()

                } else {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao cadastrar cliente, " + retorno.mensagem,
                        showConfirmButton: false,
                        timer: 1500
                    });
                }


            })
            .catch((error) => {

                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao cadastrar cliente!" + error,
                    showConfirmButton: false,
                    timer: 1500
                });
                console.log(error)
            }).finally(() => {


                if (Swal.isLoading()) {
                    Swal.close()
                }

            })



    }


    function getDadosParceiro() {

        Swal.fire({
            title: "Meus Dados",
            html: "Aguarde enquanto carregamos seus dados.",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })


        const controller = new AbortController()
        const signal = controller.signal

        AuthFetchJson(`${url}COLETAFORNECEDOR`, {
            signal,
            method: "POST",
            body: JSON.stringify({
                "A2_COD": state.usuario.A2_COD,
                "A2_LOJA": state.usuario.A2_LOJA
            })
        })
            .then(response => {

                if (response.sucesso !== "T") {

                    Swal.fire({
                        title: "Meus Dados",
                        html: "Houve um erro ao buscar seus dados." + response.mensagem
                    })

                    return
                }

                response.dados[0].A2_CGC = Utils.maskaraCnpj(response.dados[0].A2_CGC) 
                setFormulario(response.dados[0])
            })
            .catch(err => {
                Swal.fire({
                    title: "Meus Dados",
                    html: "Houve um erro ao buscar seus dados."
                })
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })

        return () => {
            controller.abort()
        }

    }


    useEffect(() => getDadosParceiro(), [])


    return (

        <div className={styles.container}>

            <Menu />
            <div className={styles.content}>

                <div className={styles.cardForm}>
                    <nav className={styles.menuSup}>
                        <p
                            onClick={() => setTab('dadosbasicos')}
                            className={tab === 'dadosbasicos' ? styles.menuSupSelected : ''}>Dados Básicos</p>
                        <p
                            onClick={() => setTab('endereco')}
                            className={tab === 'endereco' ? styles.menuSupSelected : ''}>Endereço</p>
                        <p
                            onClick={() => setTab('contato')}
                            className={tab === 'contato' ? styles.menuSupSelected : ''}>Contato</p>
                        <p
                            onClick={() => setTab('financeiro')}
                            className={tab === 'financeiro' ? styles.menuSupSelected : ''}>Financeiro</p>
                        <p
                            onClick={() => setTab('documentos')}
                            className={tab === 'documentos' ? styles.menuSupSelected : ''}>Documentos</p>
                    </nav>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <FormularioContext.Provider value={{ formulario, setFormulario }}>
                        <ArquivosContext.Provider value={{ documentos, setDocumentos }}>

                            {
                                selectedTab()
                            }
                        </ArquivosContext.Provider>
                    </FormularioContext.Provider>

                    <hr />


                    <div className={tab === "documentos" ? styles.submitNextSelected : styles.submitNext}>
                        <button onClick={handleNext}>
                            <span>Confirmar e finalizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>

            </div>
        </div>
    )
}