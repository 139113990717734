import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { useNavigate } from 'react-router-dom';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { GrGrommet, GrRadialSelected } from "react-icons/gr";
import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";
import { RxDotFilled } from "react-icons/rx";
import Calendar from "react-calendar";
import { DadosBasicos } from "./dados-basicos";
import { Endereco } from "./endereco";
import { Contato } from "./contato";
import { CadastroType, TecnicoDefault } from "../../context/parceiro-cadastro";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";
import { Menu } from "../menu";

type documentosType = {
    file: any,
    name: any
} 

export const FormularioContext = createContext<{ formulario: CadastroType, setFormulario: Dispatch<SetStateAction<CadastroType>> } | null>(null)
export const ArquivosContext = createContext<{documentos: documentosType[], setDocumentos: Dispatch<SetStateAction<documentosType[]>>}| null>(null)
export function CadastrarTecnico() {

    const { state: { usuario } } = useContext(ContextRegister)

    const [documentos, setDocumentos] = useState<{
        file: any,
        name: any
    }[]>([])

    const navigate = useNavigate();

    const [tab, setTab] = useState<'dadosbasicos' | 'endereco' | 'contato'>('dadosbasicos')

    const [formulario, setFormulario] = useState(TecnicoDefault);



    function selectedTab() {

        switch (tab) {

            case 'dadosbasicos':
                return <DadosBasicos />
            case 'endereco':
                return <Endereco />
            case 'contato':
                return <Contato />
            default:
                return <DadosBasicos />

        }

    }

    function handleNext() {

        if (tab === "contato") {


            let aux = { ...formulario }

            aux.A2_DTNASC = aux.A2_DTNASC?.replaceAll('-', '')
            aux.A2_CEP = aux.A2_CEP.replace(/\D/g, "")
            aux.A2_CGC = aux.A2_CGC.replace(/\D/g, "")
            if (aux.A2_NREDUZ === "") {
                aux.A2_NREDUZ = aux.A2_NOME.substring(0, 20)
            }


            if(aux.A2_ZOBSERV) {
                aux.A2_ZOBSERV = aux.A2_ZOBSERV.substring(0, 40)
            }

            


            Swal.fire({
                title: 'Cadastro!',
                html: 'Aguarde, salvando seus dados no sistema.',// add html attribute if you want or remove
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });


            AuthFetchJson(url + 'INSERETECNICO', {
                body: JSON.stringify(
                    { 
                        dados: aux,
                        parceiro: usuario.A2_COD,
                        loja: usuario.A2_LOJA 
                    }
                ),
                method: 'POST'
            })
                .then(async (retorno: any) => {


                    if (retorno.sucesso === 'T') {
                    
                        await Promise
                        .all(
                            documentos.map(doc =>
                                AuthFetchJson(url + "ENVIADOCUMENTOTEC", {
                                    method: "POST",
                                    body: JSON.stringify({
                                        PARCEIRO: retorno.dados[0].A2_COD,
                                        LOJA: retorno.dados[0].A2_LOJA,
                                        ARQBASE64: `${doc.file}`.replace(/^.*,/, ""),
                                        NOMEARQ: `${doc.name}`
                                    })
                                })
                            )
                        )

                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Cadastro realizado com sucesso!",
                            showConfirmButton: false,
                            timer: 1500
                        });

                        AuthFetchJson(url + 'ACEITATECNICO', {
                            body: JSON.stringify({
                                PARCEIRO: usuario.A2_COD,
                                LOJA: usuario.A2_LOJA,
                                TECNICO: retorno.dados[0].A2_COD,
                                LOJTEC: retorno.dados[0].A2_LOJA
                            }),
                            method: "POST"
                        })
                            .then( (retorno: any) => {

                                if (retorno.sucesso !== 'T') {
                                    Swal.fire({
                                        position: "top-end",
                                        icon: "error",
                                        title: "Erro ao cadastrar tecnico, " + retorno.mensagem,
                                        showConfirmButton: false,
                                        timer: 1500
                                    });
                                }
                            })
                            .catch(error => {

                                Swal.fire({
                                    position: "top-end",
                                    icon: "error",
                                    title: "Erro ao associar o cadastro do tecnico!" + error,
                                    showConfirmButton: false,
                                    timer: 1500
                                });
                                console.log(error)

                            })
                        navigate('/tecnicos')

                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: "Erro ao cadastrar tecnico, " + retorno.mensagem,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }


                })
                .catch((error) => {

                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao cadastrar tecnico!" + error,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    console.log(error)
                }).finally(() => {


                    if (Swal.isLoading()) {
                        Swal.close()
                    }

                })

        }

    }

    return (
        <div className={styles.container}>

            <Menu/>


            <div className={styles.content} >







                <div className={styles.cardForm}>
                    <nav className={styles.menuSup}>
                        <p
                            onClick={() => setTab('dadosbasicos')}
                            className={tab === 'dadosbasicos' ? styles.menuSupSelected : ''}>Dados Básicos</p>
                        <p
                            onClick={() => setTab('endereco')}
                            className={tab === 'endereco' ? styles.menuSupSelected : ''}>Endereço</p>
                        <p
                            onClick={() => setTab('contato')}
                            className={tab === 'contato' ? styles.menuSupSelected : ''}>Contato</p>
                    </nav>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <FormularioContext.Provider value={{ formulario, setFormulario }}>
                    <ArquivosContext.Provider value={{documentos, setDocumentos}}>
                        {
                            selectedTab()
                        }
                    </ArquivosContext.Provider>
                    </FormularioContext.Provider>

                    <hr />


                    {/* <div className={styles.termoContainer}>
                        <div className={styles.radioGroup}>
                            <input title="termo" type="radio" name="termo" />
                            <span>&nbsp;Eu aceito os Termos de Uso e Políticas de Privacidade.</span>
                        </div>
                    </div> */}


                    <div className={tab === 'contato' ? styles.activeSubmitNext :  styles.submitNext}>
                        <button type="button" onClick={handleNext}>
                            <span>Confirmar e finalizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>

            </div>


        </div>
    )
}