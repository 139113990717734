import { Menu } from "../menu";
import styles from "./styles.module.css"
import { useContext, useEffect, useState } from "react";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";


type PracaType = {
    ZD_CODIGO?: string
    ZD_DESCR: string
    checked?: boolean
    ZD_EST?: string
}

export function Pracas() {

    const { state } = useContext(ContextRegister)
    const [minhasPracas, setMinhasPracas] = useState<PracaType[]>([])
    const [pracas, setPracas] = useState<PracaType[]>([]);

    const [termo, setTermo] = useState("")

    function buscarMinasPracas() {

        AuthFetchJson(`${url}COLETAPRACAPARCEIRO`, {
            method: "POST",
            body: JSON.stringify({
                CODPAR: state.usuario.A2_COD,
                LOJA: state.usuario.A2_LOJA
            })

        })
            .then(response => {

                if (response.sucesso !== "T") {
                    Swal.fire("Erro ao buscar insumos associados.")
                    return;
                }
                setMinhasPracas(response.dados.map( (p: any) => ({
                    ZD_CODIGO: p.PRACA,
                    ZD_DESCR: p.DESCRICAO,
                    checked: true
                })))

            })
            .catch(error => {
                Swal.fire("Erro ao buscar insumos associados.")
                console.log(error)
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })


    }

    function handleCheckIns(index: number, obj:  PracaType){
        let aux = [ ...pracas ]

        index = aux.findIndex( a => a.ZD_CODIGO === obj.ZD_CODIGO)

        aux.splice(index, 1, { ...obj, checked: !obj.checked })
        setPracas(aux)

    }

    function handleSave() {

        Swal.fire({
            title: "Salvando",
            html: "Aguarde, gravando dados de pracas",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        let pracaSave = pracas.filter(praca => praca.checked).map(praca =>({
            praca: praca.ZD_CODIGO
        }))

        AuthFetchJson(`${url}PRACAPARCEIRO`, {
            method: "POST",
            body: JSON.stringify( {
                CODPAR: state.usuario.A2_COD,
                LOJA: state.usuario.A2_LOJA,
                itens: pracaSave
            } )
        }).finally(() => {
            if (Swal.isLoading()) {
                Swal.close()
            }
        })
    }

    useEffect(() => {

        Swal.fire({
            title: "Carregando",
            html: "Carregando praças cadastrados",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })

        AuthFetchJson(`${url}COLETAPRACA/${state.usuario.A2_EST}`)
            .then(response => {

                if (response.sucesso !== "T") {
                    Swal.fire("Erro ao buscar insumos")
                    return;
                }

                setPracas(response.dados.map((praca: any) => ({

                    "ZD_CODIGO": praca.ZD_CODIGO,
                    "ZD_DESCR": praca.ZD_DESCR,
                    "ZD_EST": praca.ZD_EST,
                    "checked": false

                })))
                buscarMinasPracas()

            })
            .catch(error => {
                Swal.fire("Erro ao buscar insumos")
                console.log(error)
            })
            .finally(() => {
                if (Swal.isLoading()) {
                    Swal.close()
                }
            })

    }, [])


    useEffect(() => {

        if(pracas.length === 0 || minhasPracas.length ===0) {
            return
        }


        let pracasInter = pracas.map(praca => {

            if(minhasPracas?.some( i => i.ZD_CODIGO === praca.ZD_CODIGO)) {
                return {
                    ...praca,
                    checked: true
                }
            }
            return praca
        })


        setPracas(pracasInter)

    }, [minhasPracas])

    return (
        <div className={styles.container}>

            <Menu />

            <div className={styles.content} >

                <div className={styles.insumoContainer}>
                    <h2>Praças</h2>
                    <input placeholder="Pesquisar praça" type="text" value={termo} onChange={event => setTermo(event.target.value)}/>


                    <div className={styles.insumosOverflow}>

                        {pracas?.filter(praca => praca.ZD_DESCR.toUpperCase().search(`${termo}`.toUpperCase()) !== -1).map((insumo, index) =>

                            <label key={insumo.ZD_CODIGO}>
                                <input type="checkbox" onChange={() => handleCheckIns(index, insumo)} value={insumo.ZD_DESCR} checked={insumo.checked} />
                                &nbsp;&nbsp;&nbsp;{insumo.ZD_DESCR}
                            </label>
                        )}
                    </div>

                    <button onClick={handleSave}>Salvar</button>

                </div>

            </div>
        </div>
    )
}