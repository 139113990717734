import Swal from "sweetalert2"

export const url = "https://api.ipest.com.br/REST/SOIPESWS/"

//export const url = "http://solutii.ddns.net:8075/REST/SOIPESWS/"


export const AuthFetchJson = async (url: string, req : RequestInit|undefined = undefined ) => {
    return fetch( url, { 
        ...req,
        headers: {
            'IPEST-TOKEN': localStorage.getItem('IPEST-TOKEN')||''
        }
     } )
    .then(res => res.json())
    .then(  async (res) => {
        if(res.sucesso === "E") {
            await Swal.fire( { 
                title: "Autenticação",
                html: "Token expirado/desativado, faça seu login novamente.",
            })

            window.location.href = "/"
        }

        return res
    })

}