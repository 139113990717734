import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { Link, useNavigate } from 'react-router-dom';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { GrGrommet, GrRadialSelected } from "react-icons/gr";
import { useEffect, useState } from "react";
import Hamburger from "hamburger-react";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { Menu } from "../menu";

export function ProtegerFamilia() {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)
    const [tiposResidencial, setTiposResidencial] = useState([])
    

    useEffect(() => {

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados dos tipos de imóveis.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(`${url}TIPOSIMOVEIS`)
        .then( (retorno:any) => {
            if(retorno.sucesso !== 'T') {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao buscar dados!" + retorno.mensagem,
                    showConfirmButton: false,
                    timer: 1500
                  });
                return;
            }

            if(Swal.isLoading()) {
                Swal.close()
            }


            setTiposResidencial(retorno.dados.filter(
                (t: any) => t.B1_DESC.search("RESIDEN") !== -1
            ))


        })
        .catch(error => {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao buscar dados!" + error,
                showConfirmButton: false,
                timer: 1500
              });
        })

    }, [])

return (

    <div className={styles.container}>

            <Menu />

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>

            <div className={styles.content} >

                    <Link to={'/home'} className={styles.urlButtonToFamily}>
                        <p>Minha Familia</p>
                    </Link>

                    <h2>Quero proteger ...</h2>

                    <div>

                        {
                            tiposResidencial.map( (tipo: any) => (

                                <Link key={tipo.B1_COD} to={'/proteger-casa/'+tipo.B1_COD}>
                                    <button >
                                        {tipo.B1_DESC}
                                    </button>
                                </Link>
                            ))
                        }
                        
                    </div>
            </div>
    </div>

)

}