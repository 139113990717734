import styles from './styles.module.css'
import logo from "../../assets/icon.png"
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaCircleChevronDown } from "react-icons/fa6";
export function Menu() {


    const [isOpen, setOpen] = useState(false)
    const [dropdownSrvOpen, setDropdownSrvOpen] = useState(false);
    const [dropdownCadOpen, setDropdownCadOpen] = useState(false);
    const navigate = useNavigate()


    return (
        <>
            <div className={styles.menu}>

                <Link to={"/home"} className={styles.linkLogo}>
                    <img alt='logo' src={logo} />
                </Link>

                <div className={styles.actions}>

                    <button onClick={() => navigate("/home")}>Pagamentos</button>

                    <div className={styles.dropdownContainer} onMouseLeave={() => setDropdownSrvOpen(false)}>
                        <button onClick={() => setDropdownSrvOpen(!dropdownSrvOpen)}>Serviços&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FaCircleChevronDown /></button>

                        {
                            dropdownSrvOpen &&
                            <div className={styles.dropdownLinks}>

                                <ul>
                                    <Link to="/servicos">
                                        <li>Buscar Serviços</li>
                                    </Link>
                                    <Link to="/meus-servicos">
                                        <li>Meus Serviços</li>
                                    </Link>
                                    {/* <li>Filiais</li>
                                <li>Usuários</li> */}
                                </ul>
                            </div>
                        }
                    </div>

                    <div className={styles.dropdownContainer} onMouseLeave={() => setDropdownCadOpen(false)}>
                        <button onClick={() => setDropdownCadOpen(!dropdownCadOpen)}>Cadastros&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <FaCircleChevronDown /></button>

                        {
                            dropdownCadOpen &&
                            <div className={styles.dropdownLinks}>

                                <ul>
                                    <Link to="/tecnicos">
                                        <li>Técnicos</li>
                                    </Link>
                                    <Link to="/insumos">
                                        <li>Insumos</li>
                                    </Link>
                                    <Link to="/pracas">
                                        <li>Praças</li>
                                    </Link>
                                    {/* <li>Filiais</li>
                                <li>Usuários</li> */}
                                </ul>
                            </div>
                        }
                    </div>


                    {/* <button>Gestão Operacional</button>
                    <button onClick={() => navigate('/tecnicos')}>Tecnicos</button>

                    <button>Gestão Financeira</button> */}

                    <Link to="/minha-conta">
                        Minha conta
                    </Link>

                    <Link to="/">
                        Sair
                    </Link>

                </div>

                <div className={styles.hamburguer}>

                    <Hamburger
                        toggled={isOpen}
                        toggle={setOpen} />

                </div>

            </div>

            {isOpen &&
                <nav className={styles.navMenu}>
                    <ul>
                        <Link to="/home">
                            <li>
                                Home
                            </li>
                        </Link>
                        <Link to="/servicos">
                            <li>
                                Buscar Serviços
                            </li>
                        </Link>
                        <Link to="/meus-servicos">
                            <li>
                                Meus Serviços
                            </li>
                        </Link>
                        <Link to="/tecnicos">
                            <li>
                                Técnicos
                            </li>
                        </Link>
                        <Link to="/insumos">
                            <li>
                                Insumos
                            </li>
                        </Link>
                        <Link to="/pracas">
                            <li>
                                Pracas
                            </li>
                        </Link>
                        <Link to="/minha-conta">
                            <li>
                                Minha Conta
                            </li>
                        </Link>
                        <Link to="/">
                            <li>
                                Sair
                            </li>
                        </Link>
                    </ul>
                </nav>
            }
        </>
    )
}