import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { useNavigate } from 'react-router-dom';
import { useState } from "react";
import { Utils } from "../../utils";
import Swal from "sweetalert2";
import { AuthFetchJson, url } from "../../api";

export function Relembrar() {
    const navigate = useNavigate();
    const [remember, setRemember] = useState("")


    async function enviarSenha() {

        Swal.fire({
            title: "Esqueci minha senha.",
            html: "Aquarde enquanto estamos enviando sua senha por e-mail",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            }
        })


        await AuthFetchJson(`${url}FORNECEDORSENHA`, {
            body: JSON.stringify({
                login: remember.replace(/\D/g, "")
                
            }),
            method: "POST"
        })
        .then(response => {

            if(response.sucesso !== "T") {

                Swal.fire({ 
                    title: "Esqueci minha senha.",
                    html: "Não foi possível reenviar sua senha." +response.mensagem
                    
                })
                return;
            } 

            Swal.fire("Cofira seu e-mail para recuperar sua senha.")
            navigate('/')

        })
        .catch(err => {
            Swal.fire({ 
                title: "Esqueci minha senha.",
                html: "Não foi possível reenviar sua senha."
                
            })
        }).finally(() => {
            if(Swal.isLoading()) {
                Swal.close()
            }
        })

 
        
    }

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <img alt="logo" src={logo} className={styles.logo} />
                <h1 className={styles.title}>Recuperação de Senha</h1>
            </div>

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>

            <div className={styles.formContainer}>

                <span className={styles.presentation}>Digite no campo abaixo o endereço de e-mail que você <br /> usou no seu cadastro e clique no botão Enviar.</span>

                <div className={styles.card}>

                    <div className={styles.inputGroup}>
                        <label>CNPJ</label>
                        <input value={remember} onChange={(event) => setRemember(Utils.maskaraCnpj(event.target.value))} placeholder="00.000.000/0000-00"></input>

                    </div>

                    <button onClick={enviarSenha}>Enviar</button>

                </div>
            </div>

        </div>
    )
}