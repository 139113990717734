import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { Link, useNavigate } from 'react-router-dom';
import { RxDotFilled } from "react-icons/rx";
import { ChangeEvent, useContext, useState } from "react";
import Hamburger from "hamburger-react";
import Calendar from 'react-calendar';
import { ContextRegister } from "../../context";
import Swal from "sweetalert2";
import { AuthFetchJson, url } from "../../api";
import { Menu } from "../menu";


type AgendamentoType = {
    CLIENTE: string,
    LOJA: "01",
    VALOR: number,
    PRECO: {
        TABELA: string,
        CEP: string,
        ASSIST: string,
        DTAGEND: string,
        TURNO: "M"|"T"|"N",
        ENDER: string,
        NUMERO: number,
        COMPLE: string,
        BAIRRO: string,
        EST: string,
        MUN: string,
        FPGTO: "P"|"C",
        TERMO: "S",
        ITENS: 
            {
                ITEM: string,
                QUANT: number,
                VRUNIT: number
            }[]
        
    }
}

type DataPiece = Date | null;
type AgendaType = DataPiece | [DataPiece, DataPiece];

export function ProtegerFormulario() {
    const navigate = useNavigate();
    const [data, setData] = useState<AgendaType>(new Date());

    const { state, dispatch} = useContext(ContextRegister);
    const [isOpen, setOpen] = useState(false)


    const [turno, setTurno] = useState<"M"|"T"|"N">("M")
    const [tipoPagamento, setTipoPagamento] = useState<"P"|"C">("P")

    function handleNext() {

        if(!data) {
            
            Swal.fire({
                title: "Selecione a Data para  o serviço!",
                icon: "error",
                timer: 1500
            })
            
            return
        }

        const formatedDate = data.toLocaleString('pt-BR', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).split('/').reverse().join('') 

        let agendamento: AgendamentoType = {
            CLIENTE: state.usuario.A2_COD,
            LOJA: state.usuario.A2_LOJA,
            VALOR: state.formacaoPreco.VALOR,
            PRECO: {
                TABELA: state.agendamento.tabela,
                CEP: state.agendamento.CEP,
                ASSIST: `${state.formacaoPreco.ASSIS}`,
                DTAGEND: formatedDate,
                TURNO: turno,
                ENDER: state.agendamento.endereco,
                NUMERO: parseInt(state.agendamento.numero)||0,
                COMPLE: state.agendamento.complemento,
                BAIRRO: state.agendamento.bairro,
                EST: state.agendamento.estado,
                MUN: state.agendamento.municipio,
                FPGTO: tipoPagamento,
                TERMO: "S",
                ITENS: state.formacaoPreco.ITENS.map(item => ({
                    ITEM: item.ITEM,
                    QUANT: item.QUANT,
                    VRUNIT: item.VRUNIT
                }))
            }
        } 

        Swal.fire({
            title: 'Aguarde!',
            html: 'Salvando seu pedido.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        AuthFetchJson(`${url}INSEREPEDIDO`, {
            method: 'POST',
            body: JSON.stringify(agendamento)
        })
        .then( (retorno: any) => {

            console.log(retorno)
            if(retorno.sucesso !== 'T') {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao gerar seu pedido, " + retorno.mensagem,
                });
                return
            }

            Swal.fire({

                title: "Parabéns",
                text: "Você selecionou a melhor proteção à segurança do seu imóvel. Em breve receberá no endereço cadastrado um especialista associado iPest, entregando toda a tranquilidade que precisam, seguindo todas as recomendações da ANVISA, e focado na melhor relação com o consumidor.",
                icon: "success",
                didClose: () => {
                    navigate("/home")
                },
                
            })
        })
        .catch(error => {

            console.log(error);
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Erro ao gerar seu pedido, tente novamente ou entre em contato!",
            });
        }).finally(() => {

            if(Swal.isLoading()) {

                Swal.close()
            }
        })


    }
    

    function updateState(event: ChangeEvent<HTMLInputElement>) {

        let aux: any = { ... state.agendamento }
        aux[event.target.name] = event.target.value
        

        dispatch({ 
            type: 'agendamento',
            payload: aux
        })
        
    }

    return (

        <div className={styles.container}>

            <Menu />

            {/* <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div> */}

            <div className={styles.content} >


                <div className={styles.cardForm}>
                    <div className={styles.legend}>
                        <span><RxDotFilled /> Obrigatório</span>
                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Agendamento do serviço</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.calendario}>
                            <Calendar locale="pt-br" onChange={setData} value={data} />
                        </div>
                    </div>

                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Agendamento do serviço</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupContainer}>
                            <div className={styles.radioGroup}>
                                <input onChange={ _ => setTurno("M")} type="radio" name="periodo" value="M" checked={turno==="M"} required/>
                                <span>&nbsp;Manhã (8h - 12h)</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input onChange={event => setTurno("T")} type="radio" name="periodo" value="T" checked={turno==="T"} required/>
                                <span>&nbsp;Tarde (13h - 17h)</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input onChange={event => setTurno("N")} type="radio" name="periodo" value="N" checked={turno==="N"} required/>
                                <span>&nbsp;Noite (18h - 22h)</span>
                            </div>

                        </div>
                    </div>

                    <hr />


                    <div className={styles.groupCardForm}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Endereço para execução</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.groupInput}>
                            <span>CEP <RxDotFilled className={styles.legend} /></span>
                            <input disabled={true} onChange={updateState}  value={state.agendamento.CEP}  type="text" name="cep" placeholder="00.000-000" />
                            <label>digite apenas números</label>
                        </div>

                        <div className={styles.groupInput}>
                            <span>Endereço <RxDotFilled className={styles.legend} /></span>
                            <input value={state.agendamento.endereco} onChange={updateState} type="text" name="endereco" placeholder="" />
                        </div>

                        <div className={styles.formGroup}>

                            <div className={styles.groupInput}>
                                <span>Número <RxDotFilled className={styles.legend} /></span>
                                <input type="text" name="numero" placeholder="" onChange={updateState}/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                            <div className={styles.groupInput}>
                                <span>Complemento <RxDotFilled className={styles.legend} /></span>
                                <input type="text" name="complemento" placeholder=""  onChange={updateState}/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                        </div>

                        <div className={styles.formGroup}>

                            <div className={styles.groupInput}>
                                <span>Estado <RxDotFilled className={styles.legend} /></span>
                                <input value={state.agendamento.estado} type="text" name="estado" placeholder="" onChange={updateState}/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                            <div className={styles.groupInput}>
                                <span>Município <RxDotFilled className={styles.legend} /></span>
                                <input value={state.agendamento.municipio} type="text" name="municipio" placeholder="" onChange={updateState}/>
                                {/* <label>digite apenas números</label> */}
                            </div>
                        </div>
                    </div>

                    <div className={styles.groupCardFormPG}>
                        <div className={styles.groupCardFormTitle}>
                            <p>Forma de pagamento</p>
                            <RxDotFilled className={styles.legend} />
                        </div>

                        <div className={styles.radioGroupPagamento}>
                            <div className={styles.radioGroup}>
                                <input type="radio" name="tipoPagamento" value={"P"} onChange={() => setTipoPagamento("P")} checked={tipoPagamento==="P"} required/>
                                <span>&nbsp;PIX</span>
                            </div>
                            <div className={styles.radioGroup}>
                                <input type="radio" name="tipoPagamento" value={"C"} onChange={() => setTipoPagamento("C")} checked={tipoPagamento==="C"} required/>
                                <span>&nbsp;Cartão de crédito</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.termoContainer}>
                        <div className={styles.radioGroup}>
                            <input type="radio" name="termo" required/>
                            <span>&nbsp;Eu aceito os Termos de Uso e Políticas de Privacidade.</span>
                        </div>
                    </div>

                    
                    <div className={styles.submitNext}>
                        <button onClick={handleNext}>
                            <span>Confirmar e finalizar</span>
                            <span>{' > '}</span>
                        </button>
                    </div>

                </div>
            </div>
        </div>

    )

}