import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { Link, useNavigate } from 'react-router-dom';
import { ReactNode, useContext, useEffect, useState } from "react";
import { AuthFetchJson, url } from "../../api";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";

import { MdOutlineStarOutline, MdOutlineStarPurple500 } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { Menu } from "../menu";

export function Tecnicos() {


    const { state: { usuario } } = useContext(ContextRegister)

    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)

    const [tecnicos, setTecnicos] = useState([]);


    const [servicos, setServicos] = useState([])
    const turno: any = {
        "M": "Manhã",
        "T": "Tarde",
        "N": "Noite",
    }

    const status: any = {
        1: "Aguardando",
        2: "Cancelado",
        3: "Finalizado"
    }

    const formaterCurrency = new Intl.NumberFormat('pt-br', {
        currency: 'BRL',
        style: 'currency'
    })


    function starts(avaliacao: number): ReactNode[] {


        let whiteStars = 5 - avaliacao

        let fullStars = avaliacao


        let icons: ReactNode[] = []

        for (let i = 0; i < fullStars; i++) {
            icons.push(<MdOutlineStarPurple500 />)
        }

        for (let i = 0; i < whiteStars; i++) {
            icons.push(<MdOutlineStarOutline />)
        }

        return icons

    }

    function dateFormater(dataStr: string) {

        //dataStr AAAAMMDD
        return new Date(`${dataStr.slice(0, 4)}-${dataStr.slice(4, 6)}-${dataStr.slice(6, 8)}`).toLocaleString('pr-BR', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        })

    }

    const { state, dispatch } = useContext(ContextRegister)

    async function buscarServicos() {

        Swal.fire({
            title: 'Aguarde!',
            html: 'Buscando dados dos serviços.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });


        AuthFetchJson(`${url}COLETAFORNECEDOR`, {
            method: "POST",
            body: JSON.stringify(
                { 
                    TECFOR: "S",
                    "LOJA": usuario.A2_LOJA,
                    "PARCEIRO": usuario.A2_COD 
            })
        })
            .then((retorno: any) => {
                if (retorno.sucesso !== 'T') {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Erro ao buscar os servicos!" + retorno.mensagem,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    return;
                }

                if (Swal.isLoading()) {
                    Swal.close()
                }


                setTecnicos(retorno.dados)


            })
            .catch(error => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Erro ao buscar dados!" + error,
                    showConfirmButton: false,
                    timer: 1500
                });
            })

    }


    useEffect(() => {


        buscarServicos()
        

    }, [])

    return (

        <div className={styles.container}>

            <Menu />

            <div className={styles.content} >

                <div className={styles.actionContainer}>
                    <h2>Técnicos</h2>
                    <button onClick={() => navigate('/cadastro-tecnico')}>Cadastrar</button>
                </div>

                <table>
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>Telefone</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>


                        {
                            tecnicos.map((tecnico: any) => (
                                <tr key={tecnico.A2_COD}>
                                    <td>{tecnico.A2_COD}</td>
                                    <td>{tecnico.A2_NOME}</td>
                                    <td>{tecnico.A2_TEL}</td>
                                    <td><Link to={"/editar-tecnico/" + btoa(tecnico.A2_COD)}><FaRegEdit /></Link></td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>

            </div>
        </div>

    )

}