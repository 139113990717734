import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { ChangeEvent, useContext, useState } from "react"
import { FormularioContext } from ".."

export function Financeiro() {

    const context = useContext(FormularioContext)


    function atualizarFormulario(event: React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase()



        context?.setFormulario({ ...context.formulario, ...{ [nome]: value } })

    }

    return (
        <>
            <br />
            <div className={styles.formGroup}>
                <div className={styles.groupInput}>
                    <span>Banco <RxDotFilled className={styles.legend} /></span>
                    <input value={context?.formulario.A2_BANCO} type="text" name="A2_BANCO" onChange={atualizarFormulario} placeholder="Código do Banco" />
                </div>
            </div>
            <div className={styles.groupInput}>
                <span>Agência <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A2_AGENCIA} type="text" name="A2_AGENCIA" onChange={atualizarFormulario} placeholder="" style={{ width: "100%" }} />
            </div>
            <div className={styles.groupInput}>
                <span>Número da Conta <RxDotFilled className={styles.legend} /></span>
                <input maxLength={20} value={context?.formulario.A2_NUMCON} type="text" name="A2_NUMCON" onChange={atualizarFormulario} placeholder="" style={{ width: "100%" }} />
            </div>

            <div className={styles.groupInput}>
                <span>PIX</span>
                <input maxLength={20} value={context?.formulario.A2_ZPIX} type="text" name="A2_ZPIX" onChange={atualizarFormulario} placeholder="" style={{ width: "100%" }} />
            </div>

        </>

    )

}