import { createContext } from "react";






export const defaultValues: defaultContextType = {

    usuario: {
        A2_COD: "",
        A2_LOJA: "01",
        A2_NOME: "",
        A2_EST: ""
    },

    imoveis: [{
        B1_COD: "",
        B1_DESC: ""
    }],

    formacaoPreco: {
        ITENS: [{
            ITEM: "001",
            QUANT: 1,
            VRUNIT: 150,
            DESCR: '',
            GRUPO: ''
        }],
        ASSIS: "30",
    
        VALOR: 0,

    },

    agendamento : {
        CEP: '',
        endereco: '',
        numero: '',
        complemento: '',
        estado: '',
        municipio: '',
        bairro: '',
        pagamento: '',
        tabela: ''
    }

}


export type usuarioType = {

        A2_COD: string
        A2_LOJA: "01"
        A2_NOME: string
        A2_EST: string
}

export type imovelType = {
    B1_COD: string
        B1_DESC: string
}

export type itemType = {
    ITEM: string,
        QUANT: number,
        VRUNIT: number,
        DESCR: string,
        GRUPO: string
}

export type formacaoPrecoType = {
    ITENS: itemType[]
    ASSIS: string
    VALOR: number
}

export type agendamentoType = {
    CEP: string,
    endereco: string,
    numero: string,
    complemento: string,
    estado: string,
    municipio: string,
    bairro: string,
    pagamento: string
    tabela: string
}


export type defaultContextType = {
    
    usuario: usuarioType
    imoveis:  imovelType[]
    formacaoPreco: formacaoPrecoType
    agendamento: agendamentoType
}

export type actionsType = 'usuario'|'imoveis'|'formacaoPreco'|'agendamento'


    export const ContextRegister = createContext<{
        state: defaultContextType;
        dispatch: React.Dispatch<{ type: actionsType, payload: any}>;
    }>({ state: defaultValues, dispatch: () => null})
