import { useReducer } from 'react';
import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";

import 'react-calendar/dist/Calendar.css';

import { Login } from './telas/login';
import { Home } from './telas/home/indext';
import { Relembrar } from './telas/relembrar';
import { Cadastro } from './telas/cadastro';
import { ProtegerFamilia } from './telas/proteger-familia';
import { ProtegerCasa } from './telas/proteger-casa';
import { ProtegerConfirmacao } from './telas/proteger-confirmacao';
import { ProtegerFormulario } from './telas/proteger-formulario';
import { CadastroFormulario } from './telas/cadastro-formulario';
import { CadastrarTecnico } from './telas/cadastro-tecnico';
import { EditarTecnico } from './telas/editar-tecnico';
import { defaultValues, ContextRegister, defaultContextType } from './context/index'
import { reducerDefault } from './reducer/index'
import { Servicos } from './telas/servicos';
import { Tecnicos } from './telas/tecnicos';
import { MeusServicos } from './telas/meus-servicos';
import { Insumos } from './telas/insumos';
import { MinhaConta } from './telas/minha-conta';
import { Pracas } from './telas/pracas';



function App() {

  function getValues(): defaultContextType {



    let usuario: any =  localStorage.getItem("usuario");

    if(usuario === null) {
      return defaultValues
    }

    usuario = JSON.parse(usuario)




      return {
        ...defaultValues, ...{ usuario: usuario}
      }
    
  } 

  const [state, dispatch] = useReducer(reducerDefault, getValues());
  return (
    <BrowserRouter>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}

        <ContextRegister.Provider value={{state, dispatch}}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/home" element={<Home />} />
            <Route path="/relembrar" element={<Relembrar />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/proteger-familia" element={<ProtegerFamilia />} /> 
            <Route path="/proteger-casa/:tipo" element={<ProtegerCasa />} />
            <Route path="/proteger-confirmacao/:valor" element={<ProtegerConfirmacao />} />
            <Route path="/proteger-formulario" element={<ProtegerFormulario />} />
            <Route path="/cadastro-formulario" element={<CadastroFormulario />} />
            <Route path="/cadastro-tecnico" element={<CadastrarTecnico />} />
            <Route path="/editar-tecnico/:A2_COD" element={<EditarTecnico />} />
            <Route path="/servicos" element={<Servicos />} />
            <Route path="/meus-servicos" element={<MeusServicos />} />
            <Route path="/tecnicos" element={<Tecnicos />} />
            <Route path="/insumos" element={<Insumos />} />
            <Route path="/minha-conta" element={<MinhaConta />} />
            <Route path="/pracas" element={<Pracas />} />
          </Routes>
        </ContextRegister.Provider>
    </BrowserRouter>
  );
}

export default App;
