import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { ChangeEvent, useContext, useState } from "react"
import { FormularioContext } from ".."
import { Utils } from "../../../utils"

export function DadosBasicos() {

    const context = useContext(FormularioContext)

    function atualizarFormulario(event: React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase()


        if (nome === 'A2_CGC') {
            value = Utils.maskaraCpfCnpj(value)

            context?.setFormulario({ ...context.formulario, ...{ [nome]: value, "A2_TIPO": value.length > 14 ? "J" : "F" } })

        }
        else {

            context?.setFormulario({ ...context.formulario, ...{ [nome]: value } })
        }

    }



    return (
        <>
            <br />
            <div className={styles.formGroup}>
                <div className={styles.groupInput}>
                    <span>CPF/CNPJ <RxDotFilled className={styles.legend} /></span>
                    <input value={context?.formulario.A2_CGC} type="text" name="A2_CGC" onChange={atualizarFormulario} placeholder="000.000.000-00" />
                    <label>digite apenas números</label>
                </div>
                {/* <div className={styles.groupInput}>
                <span>Data de Nascimento <RxDotFilled className={styles.legend} /></span>
                <input value={context?.formulario.A2_DTNASC} type="date" name="A2_DTNASC" onChange={atualizarFormulario} placeholder="00/00/0000" />
                <label>digite apenas números</label>
            </div> */}
            </div>
            <div className={styles.groupInput}>
                <span>Nome <RxDotFilled className={styles.legend} /></span>
                <input maxLength={40} value={context?.formulario.A2_NOME} type="text" name="A2_NOME" onChange={atualizarFormulario} placeholder="" style={{ width: "100%" }} />
                {/* <label>digite apenas números</label> */}
            </div>
            {context?.formulario.A2_TIPO === 'J' &&
                <div className={styles.groupInput}>
                    <span>Nome Fantasia <RxDotFilled className={styles.legend} /></span>
                    <input value={context?.formulario.A2_NREDUZ} type="text" name="A2_NREDUZ" onChange={atualizarFormulario} placeholder="" style={{ width: "100%" }} />
                    {/* <label>digite apenas números</label> */}
                </div>
            }

            {context?.formulario.A2_TIPO === 'J' &&
                <div className={styles.formGroup}>
                    <div className={styles.groupInput}>
                        <span>Inscrição Estadual</span>
                        <input value={context?.formulario.A2_INSCR} type="text" name="A2_INSCR" onChange={atualizarFormulario} placeholder="" />
                        <label>digite apenas números</label>
                    </div>
                    <div className={styles.groupInput}>
                        <span>Inscrição Municipal <RxDotFilled className={styles.legend} /></span>
                        <input value={context?.formulario.A2_INSCRM} type="text" name="A2_INSCRM" onChange={atualizarFormulario} placeholder="" />
                        <label>digite apenas números</label>
                    </div>
                </div>
            }

            <div className={styles.groupInput}>
                <span>Senha (preencha apenas se desejar alterar a senha)</span>
                <input maxLength={20} value={context?.formulario.A2_ZSENHA} type="password" name="A2_ZSENHA" onChange={atualizarFormulario} placeholder="Senha de Acesso" style={{ width: "100%" }} />
                {/* <label>digite apenas números</label> */}
            </div>

            <div className={styles.groupInput}>
                <span>Confirmação de senha </span>
                <input maxLength={20} value={context?.formulario.A2_ZSENHA_CONFIRM} type="password" name="A2_ZSENHA_CONFIRM" onChange={atualizarFormulario} placeholder="Senha de Acesso" style={{ width: "100%" }} />
                {/* <label>digite apenas números</label> */}
            </div>

        </>

    )

}