import styles from "./styles.module.css"
import logo from "../../assets/icon.png"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RxDotFilled } from "react-icons/rx";
import { useContext, useState } from "react";
import Hamburger from "hamburger-react";
import { ContextRegister } from "../../context";
import { Menu } from "../menu";

export function ProtegerConfirmacao() {
    const navigate = useNavigate();
    
    const { state, dispatch } = useContext(ContextRegister);

    const currencyFormater =  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

    function handleNext() {
        navigate('/proteger-formulario')
    }

    function getPragas() {
        return state.formacaoPreco.ITENS.filter(item => item.GRUPO === "IP03")
    }

    function getInfoAdicionais() {
        return state.formacaoPreco.ITENS.filter(item => item.GRUPO === "IP04")
    }

    function getImovel() {
        return state.formacaoPreco.ITENS.filter(item => item.GRUPO === "IP02")
    }



    const [isOpen, setOpen] = useState(false)


    return (

        <div className={styles.container}>

            <Menu />

            <div className={styles.backContainer}>
                <p onClick={() => navigate('/')}>
                    {'< Voltar'}
                </p>
            </div>

            <div className={styles.content} >

                <div className={styles.cardForm}>

                    <div className={styles.groupCardFormTitle}>
                        <p>Você selecionou proteção para uma <b>{getImovel().map(item => item.DESCR)}</b>, contra {' '} 
                        { getPragas().map(praga => <b key={praga.DESCR}>{praga.DESCR}, </b>)}

                        
                            e em sua família há presença de <b>PET SOLTO e CRIANÇA ATÉ 2 ANOS</b>, com uma assistência técnica de <b>90 DIAS.</b></p>

                    </div>

                    <div className={styles.valuesPseudoButton}>


                        <p className={styles.valuesPseudoButtonTitle}>Valor do Investimento</p>

                        <div>
                            <p>{ currencyFormater.format(state.formacaoPreco.VALOR)}</p>
                        </div>

                    </div>

                    <div className={styles.submitNext}>
                        <button>Cancelar</button>
                        <button onClick={handleNext}>
                            Confirmar e continuar
                            &nbsp;&nbsp;&nbsp;{' >'}
                        </button>
                    </div>

                </div>
            </div>
        </div>

    )

}