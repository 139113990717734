import styles from "./styles.module.css"
import { Link, useNavigate } from 'react-router-dom';

import logo from "../../assets/icon.png"
import { FaRegEyeSlash, FaRegEye, FaBug } from "react-icons/fa";
import { GrGrommet, GrRadialSelected } from "react-icons/gr";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ContextRegister } from "../../context";
import { AuthFetchJson, url } from "../../api";
import { Utils } from "../../utils";


export function Login() {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(ContextRegister);

    const [statusPassword, setStatusPassword] = useState(false)
    const [remember, setRemember] = useState(true)
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')


    async function handleLogin() {

        let loginaux = login.replace(/\D/g, "")

        if(loginaux.length < 14 || senha === '') {

            if(Swal.isLoading()) {
                Swal.close()
            }
            
            Swal.fire( { 
                title: 'Acesso', 
                timer: 1500, 
                allowOutsideClick: true,
                html: 'Preencha todos os campos corretamente',

            } );
            return;
        }

        Swal.fire({
            title: 'Autenticaçao!',
            html: 'Aguarde, entrando no sistema.',// add html attribute if you want or remove
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        });

        if(remember) {
            localStorage.setItem("authentication_p", JSON.stringify({
                login, senha
            }))
        } else {
            localStorage.removeItem("authentication_p")
        }


        AuthFetchJson(`${url}FORNECEDORLOGIN`, {
            body: 
            
                JSON.stringify(
                    {
                        "LOGIN": loginaux,
                        "SENHA":  senha,
                        "ZTIPFOR": "P"
                    }

                ),
            method: 'POST'
            
        })
            .then(responseJson => {

                if(responseJson.sucesso === 'T') {

                    dispatch({
                        type: 'usuario',
                        payload: responseJson.dados[0]
                    })
                    
                    localStorage.setItem("usuario", JSON.stringify(responseJson.dados[0]))
                    localStorage.setItem("IPEST-TOKEN", responseJson.dados[0].TOKEN)

                    navigate('/home')
                    
                }

                if(responseJson.sucesso === 'F') {

                    localStorage.removeItem("usuario")

                    Swal.fire({
                        title: 'Autenticaçao!',
                        html: 'Acesso incorreto.',// add html attribute if you want or remove
                        
                    });
                }

            })
            .catch(err => {


                localStorage.removeItem("usuario")

                Swal.fire({
                    title: 'Autenticaçao!',
                    html: 'Erro ao entrar no sistema, tente novamente e se persistir entre em contato com o administrador.',// add html attribute if you want or remove
                    
                });

                console.log(err)
            })
            .finally(() => {

                if(Swal.isLoading()) {
                    Swal.close()
                }
            })

    }

    useEffect(() => {

        if(login === "" && senha === "") {

            let auth: any = localStorage.getItem("authentication_p")
            
            console.log(auth)

            if(auth) {
                auth = JSON.parse(auth)

                setLogin(auth.login)
                setSenha(auth.senha)

            }

        }

    }, [])

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <img alt="logo" src={logo} className={styles.logo} />
                <h1 className={styles.title}>Bem vindo a Ipest 
                <FaBug className={styles.buicon}/></h1>
            </div>

            <div className={styles.formContainer}>

                <span className={styles.presentation}>A plataforma para prestação de serviço descomplicada
                <br /> de proteção contra pragas.
                </span>

                <div className={styles.card}>

                    <div className={styles.inputGroup}>
                        <label>CNPJ</label>
                        <input value={login} onChange={event => setLogin(Utils.maskaraCnpj(event.target.value))} placeholder="00.000.000/0000-00"></input>

                    </div>

                    <div className={styles.inputGroup}>
                        <label>Senha</label>
                        <input value={senha}  onChange={event => setSenha(event.target.value)} placeholder="******" type={statusPassword ? 'text' : 'password'}>
                        </input>
                        <sub>6 ou mais caracteres</sub>
                        {statusPassword ?
                            <FaRegEyeSlash onClick={() => setStatusPassword(!statusPassword)} />
                            :
                            <FaRegEye onClick={() => setStatusPassword(!statusPassword)} />
                        }
                    </div>


                    <div className={styles.rememberContainer}>
                        <Link to={'relembrar'} ><a>Esqueci minha senha</a></Link>
                        <div>

                            {
                                remember ?
                                <GrRadialSelected onClick={() => setRemember(r => !r)} />
                                :
                                <GrGrommet onClick={() => setRemember(r => !r)} />
                            }
                            <span>Lembrar de mim</span>
                        </div>
                    </div>


                    <button onClick={handleLogin}>Entrar na conta</button>

                    <div className={styles.newRegisterContainer}>

                        <span>Ainda não tem conta?</span>
                        <Link to={'/cadastro-formulario'}><a>Fazer Cadastro</a></Link>

                    </div>

                </div>
            </div>

        </div>
    )
}