import { RxDotFilled } from "react-icons/rx"
import styles from "../styles.module.css"
import { useContext, useRef } from "react"
import { Utils } from "../../../utils"
import { FormularioContext } from ".."
import { ArquivosContext } from "../index"
import Swal from "sweetalert2"
import { MdDriveFolderUpload } from "react-icons/md"
import { FiTrash2 } from "react-icons/fi"

export function DadosBasicos() {

    const  contextForm = useContext(FormularioContext)

    const  context = useContext(ArquivosContext)

    const uploadInputRef = useRef<any>();

    async function atualizarDocumentos(event: React.ChangeEvent<HTMLInputElement>) {

        Swal.fire({
            title: "Upload",
            html: "Carregando arquivos...",
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading()
            },
        })

        await new Promise(r => setTimeout(r, 1000))

        if (event.target.files === null) {
            Swal.fire({
                title: "Upload",
                html: "Erro ao carregar arquivos."
            })
            return
        };

        let arquivos: any = []

        for (let i = 0; i < event.target.files.length; i++) {

            await getBase64(event.target.files[i])
                .then(arquivo => arquivos.push(arquivo))
                .catch(error => {
                    Swal.fire({
                        title: "Upload",
                        html: "Erro ao carregar arquivos."
                    })
                })
        }

        context?.setDocumentos([...context.documentos, ...arquivos])



        event.target.value = ""

        if (Swal.isLoading()) {
            Swal.close()
        }

    }

    function getBase64(file: any): Promise<{ file: string, name: string,}| Error> {

        return new Promise((resolve, reject) => {

            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {

                resolve({
                    file: `${reader.result}`,
                    name: file.name
                })

            };
            reader.onerror = function (error) {

                reject(error)

            };

        })

    }

    function removerArquivo(index: number) {

        if(!context?.documentos) {
            return
        }

        let aux = [...context.documentos]

        aux.splice(index, 1)

        context.setDocumentos(aux)
    }

    function atualizarFormulario(event : React.ChangeEvent<HTMLInputElement>) {

        let nome = event.target.name
        let value = `${event.target.value}`.toUpperCase()


        if(nome === 'A2_CGC') {
            value = Utils.maskaraCpf(value)

            contextForm?.setFormulario({ ...contextForm.formulario, ...{ [nome]: value, "A2_TIPO": value.length > 14 ? "J" : "F"}})
            
        }
        else {

            contextForm?.setFormulario({ ...contextForm.formulario, ...{ [nome]: value}})
        }

    }

    return (
        <>
        <br/>
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>CPF/CNPJ <RxDotFilled className={styles.legend} /></span>
                <input value={contextForm?.formulario.A2_CGC} type="text" name="A2_CGC" onChange={atualizarFormulario} placeholder="000.000.000-00" />
                <label>digite apenas números</label>
            </div>
            {/* <div className={styles.groupInput}>
                <span>Data de Nascimento <RxDotFilled className={styles.legend} /></span>
                <input value={contextForm?.formulario.A2_DTNASC} type="date" name="A2_DTNASC" onChange={atualizarFormulario} placeholder="00/00/0000" />
                <label>digite apenas números</label>
            </div> */}
        </div>
        <div className={styles.groupInput}>
            <span>Nome <RxDotFilled className={styles.legend} /></span>
            <input title="nome" maxLength={40} value={contextForm?.formulario.A2_NOME} type="text" name="A2_NOME" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        { contextForm?.formulario.A2_TIPO === 'J' &&
        <div className={styles.groupInput}>
            <span>Nome Fantasia <RxDotFilled className={styles.legend} /></span>
            <input title="nome_fantasia" value={contextForm?.formulario.A2_NREDUZ} type="text" name="A2_NREDUZ" onChange={atualizarFormulario} placeholder="" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>
        }

        { contextForm?.formulario.A2_TIPO === 'J' &&
        <div className={styles.formGroup}>
            <div className={styles.groupInput}>
                <span>Inscrição Estadual</span>
                <input title="inscricao_estadual" value={contextForm?.formulario.A2_INSCR} type="text" name="A2_INSCR" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
            <div className={styles.groupInput}>
                <span>Inscrição Municipal <RxDotFilled className={styles.legend} /></span>
                <input  title="inscricao_municipal" value={contextForm?.formulario.A2_INSCRM} type="text" name="A2_INSCRM" onChange={atualizarFormulario} placeholder="" />
                <label>digite apenas números</label>
            </div>
        </div>
        }

        <div className={styles.groupInput}>
            <span>Senha <RxDotFilled className={styles.legend} /></span>
            <input maxLength={20} value={contextForm?.formulario.A2_ZSENHA} type="password" name="A2_ZSENHA" onChange={atualizarFormulario} placeholder="Senha de Acesso" style={{width: "100%"}} />
            {/* <label>digite apenas números</label> */}
        </div>


        <span>Anexar Comprovante de Relação de Trabalho.<RxDotFilled className={styles.legend} /></span>

        <div className={styles.uploadContainer}>
            <input ref={uploadInputRef} type='file' onChange={atualizarDocumentos} name="A2_ZOBSERV" placeholder="Documentos" multiple style={{ visibility: "hidden", height: 0 }} />
            <button className={styles.buttonUpload} onClick={() => uploadInputRef?.current?.click()}><MdDriveFolderUpload /> Upload </button>
            <div>
                {
                    context?.documentos.map((doc, index) =>

                        <div className={styles.fileRow} key={`${index}file`}>
                            <h3>{doc.name}</h3>
                            <button className={styles.removeFileButton} onClick={() => removerArquivo(index)}><FiTrash2 /> Remover</button>
                        </div>
                    )
                }

            </div>
        </div>
        </>

            )

}